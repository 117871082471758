import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {

  constructor(
    public translate: TranslateService) {

    // Translator init
    /*const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|nl/) ? browserLang : 'en');*/
  }

  ngOnInit() {
  }

}
