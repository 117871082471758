export class Pricerule {
  public __document__key: string;
  public _addedon: number;
  public _updatedon: number;

  public licenseholder_id: string | null;
  public name_for_admin: string | null;
  public name_for_users: string | null;


  /* > 1 = match all rules
match all the rules
dont match any of the exception rules
> 2 = match one of the rules
match one of the rules
dont match any of the exception rules
> 3 = match at least the most important rule
match the most important rule according to weight
dont match any of the exception rules
> 4 = match only one of the rules
match one rule, but not the others
dont match any of the exception rules
*/
  public match_all: number;


  /* - match_users
> 1 = shouldnt match users
> 2 = should match these specific users (rule only applies for a few)
> 3 = should NOT match these specific users (exclude rule for a few)
*/
  public match_contacts: number;

  public match_rules_arr: string[];
  public match_contacts_arr: string[];
  public pricechange_arr: string[];

  public active: number;

}
