import {Injectable} from '@angular/core';
import {DefinedInfo} from '../models/definedinfo';
import {IntegrationService} from './integration.service';

const {Parser} = require('json2csv');

@Injectable()
export class CsvService {
  constructor(private definedInfo: DefinedInfo, private integrationService: IntegrationService) {
  }

  generateCSV(fields, data) {
    const json2csvParser = new Parser({fields});
    const csv = json2csvParser.parse(data);

    return csv;
  }

  processShipmentToCsv(shipments, type) {
    const forParser = {fields: [], data: []};

    shipments.forEach((shipment) => {
      let object = {};

      if (type === 'logiparcel') {

        forParser['fields'] = this.definedInfo.csv_integrations.logiparcel;
        this.definedInfo.csv_integrations.logiparcel.forEach((item) => {
          object[item] = '';
        });

        object = this.integrationService.logiparcelCsvExport(shipment, object);
      }

      forParser['data'].push(object);
    });

    return forParser;
  }
}
