import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {AuthService} from '../services/auth/auth.service';
import 'rxjs-compat/add/operator/first';

@Injectable()
export class AuthResolver implements Resolve<any> {

  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot) {
    /*return this.authService._currentAuthenticatedUser.asObservable().first();*/
    return true;
  }
}
