import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';
import {User} from '../../models/user.model';
import {Shipment} from '../../models/shipment.model';
import {PermissionService} from './permission.service';

@Injectable()
export class UserService {

  public users: User[] = [];
  public _users = new BehaviorSubject(null);
  public usersRef: AngularFirestoreCollection<any>;
  public usersObserve$: Observable<any>;
  public usersListener = new BehaviorSubject([]);


  activeUser: string | null = null;

  constructor(private db: AngularFirestore, private filterService: FilterService, public permissionService: PermissionService) {
    if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
      this.usersRef = db.collection<any>('environment/' + environment.name + '/users',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
      );
    } else {
      this.usersRef = db.collection<any>('environment/' + environment.name + '/users',
        ref => ref
          .where('licenseholder_id', '==', environment.licenseholder_id)
          .where('contact_id', '==', environment.currentAuthUser.contact_id)
      );
    }
    this.usersObserve$ = this.usersRef.snapshotChanges().pipe(
      tap (t => {
        this.users = [];
      }),
      map(actions => actions.map(user => {

        let doc_data = user.payload.doc.data();
        doc_data['__document__key'] = user.payload.doc.id;

        if (user['type'] === 'added') {
          this.users.push(doc_data);
        }

        if (user['type'] === 'modified') {
          /*  const key = this.filterService.findArrayMultiKey(this.girls, 'id', doc_data.id);
            this.girls[key] = doc_data;*/
          this.users.push(doc_data);
        }

        if (user['type'] === 'removed') {
          const key = this.filterService.findArrayMultiKey(this.users, 'id', doc_data.id);
          delete(this.users[key]);
        }

        return user;
      }))
    );

    this.usersObserve$.subscribe(() => {
      this.usersListener.next([Date.now()]);
    });
  }

  addUser(user: any) {
    return this.usersRef.add(user);
  }


  getUsers(id: string) {
    const key = this.filterService.findArrayMultiKey(this.users, '__document__key', id);
    return this.users[key];
  }

  updateUser(user_id: string, user: any) {
    const user_c = {...user};
    delete user_c.__document__key;

    const usersRef = this.db
      .collection<any>('environment/' + environment.name + '/users')
      .doc(user_id);

    const getDoc = usersRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(user_c);

        return usersRef.update(user_c);
      }
    });

    return getDoc;
  }

  getUsersByUsername(username: string) {
    const userRef = this.db.collection('environment/' + environment.name + '/users',
      ref => ref.where('username', '==', username));
    return userRef.get()
      .subscribe(snapshot => {
        if (snapshot.empty) {
          return false;
        }

        snapshot.forEach(doc => {
          return doc.data();
          /*console.log(doc.id, '=>', doc.data());*/
        });
      });

/*    console.log(this.users);
    const key = this.filterService.findArrayMultiKey(this.users, 'username', id);
    return this.users[key];*/
  }

  deleteUser(user_id: string) {
    return this.usersRef.doc(user_id).delete();
  }


}




/*import {Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {User} from '../../models/user.model';
import {DataManagementService} from './data_management.service';
import {environment} from '../../../../environments/environment';
import {TokenHolder} from '../auth/tokenholder';*/

/*
@Injectable()
/!* Service used for logged in User *!/
export class UserService {

  private apiUrl = environment.apiURL;
  _currentUser = new EventEmitter<any>();
  currentUser: User | null = null;

  constructor(private http: HttpClient, private dataManagementService: DataManagementService,
              private tokenHolder: TokenHolder) {
     this.dataManagementService.getData('current_user', this.currentUser).then(
      (user) => {
        this.currentUser = user;
      }
    );
  }

  getUserData() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.tokenHolder.token.access_token
      })
    };

      const params = null;
      this.http.get<any>(this.apiUrl + '/v2/user', {
        params: params,
        headers: httpOptions.headers
      }).subscribe (
        (user_r) => {
          this.currentUser = user_r;
          this._currentUser.emit(user_r);
          this.dataManagementService.saveData('current_user', this.currentUser);
        });



  }

  setUserData(id: number, user: User) {

  }
}
*/
