import {EventEmitter, Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {Calculation, PriceRuleApplied} from '../models/calculation.model';
import {FilterService} from './filter.service';
import {ProductService} from './data-sync/product.service';
import {PriceruleService} from './data-sync/pricerule.service';
import {MatchRulesService} from './match-rules.service';
import {DataManagementService} from './data-sync/data_management.service';
import {environment} from '../../../environments/environment';
import {ContactsService} from './data-sync/contacts.service';
import {ShipmentService} from './data-sync/shipment.service';

@Injectable()
export class PriceCalculationService {
  public _init = new Subject();
  public calculation: Calculation[] = [];
  public pricerules;
  public rulematches;
  public pricingchanges;

  constructor(private db: AngularFirestore, private filterService: FilterService,
              private productService: ProductService, private priceruleService: PriceruleService,
              private matchRulesService: MatchRulesService, private dataManagementService: DataManagementService,
              private contactsService: ContactsService, private shipmentService: ShipmentService) {
    /* init productlists, pricerules, rule_matches, pricechanges */
    /** TODO remove subscriptions here */
    this.pricerules = [...this.priceruleService.pricerules];
    this.priceruleService.pricerulesObserve$.subscribe(() => {
      this.pricerules = [...this.priceruleService.pricerules];
    });


    this.rulematches = [...this.priceruleService.rulematches];
    this.priceruleService.rulematchesObserve$.subscribe(() => {
      this.rulematches = [...this.priceruleService.rulematches];
    });


    this.pricingchanges = [...this.priceruleService.pricingchanges];
    this.priceruleService.pricingchangesObserve$.subscribe(() => {
      this.pricingchanges = [...this.priceruleService.pricingchanges];
    });

    if (this.productService.productlists === undefined || this.productService.productlists.length === 0) {
      this.productService.productlistsObserve$.subscribe(() => {

      });
    } else {

    }

  }

  mainTask(shipment) {
    const shipment_id = shipment.__document__key;

    this.calculation[shipment_id] = new Calculation;
    this.calculation[shipment_id].shipment = shipment;

    /* does shipment have a fixed price */
    const fixed_price = this.searchFixedPrice(shipment);
    if (fixed_price) {
      this.calculation[shipment_id].internal_logic.after_price_changes.push({
        short_name: 'fixed_price',
        visible_name: 'Shipment fixed price',
        price: +fixed_price,
        change_type: 'fixed_price',
        change: null
      });
      this.calculation[shipment_id].success = true;

      this.calculation[shipment_id].internal_logic.total_shipment_price = +fixed_price;
      return this.calculation[shipment_id];
    }


    if (!this.shipmentService.checkShipment(shipment)) {
      this.calculation[shipment_id].success = false;
      this.calculation[shipment_id].error = 'shipment incorrect';
      this.calculation[shipment_id].internal_logic.total_shipment_price = null;
      return this.calculation[shipment_id];
    }

    /* filter product/pricelists by user_id */
    const filteredPricelists = [];
    this.productService.productlists.forEach((productlist, prod_index, productlists) => {
      /*console.log(productlist);*/
      if (productlist.pricelists !== undefined && productlist.pricelists.length > 0) {
        productlist.pricelists.forEach((pricelist, price_index, pricelists) => {
          /*console.log(pricelist);*/
          if (pricelist.contact_ids !== undefined && pricelist.contact_ids.length > 0) {
            if (pricelist.contact_ids.includes(shipment.contact_id)) {
              filteredPricelists.push(pricelist);
            }
          }
        });
      }
    });


    /* merge all filtered pricelists */
    let mergedPricelistPrices = [];
    filteredPricelists.forEach((pricelist, price_index, pricelists) => {
      mergedPricelistPrices = mergedPricelistPrices.concat(pricelist.prices);
    });

    if (mergedPricelistPrices === undefined || mergedPricelistPrices.length === 0) {
      /*      this.calculation[shipment_id].success = false;
            return this.calculation[shipment_id];*/
    }


    /* foreach product with UID, lookup in single pricelist arr-object
    * also look for staffels
    * */
    let failedProductPrices;
    let pricePerUnit;
    let priceChangesProduct = [];
    shipment.products.forEach((product, product_index, products) => {

/*      if (product.amount > 1 && shipment.products.length > 1) {
        this.calculation[shipment_id].success = false;
        this.calculation[shipment_id].error = 'cant be calculated due to product amount';
        this.calculation[shipment_id].internal_logic.total_shipment_price = null;
        return this.calculation[shipment_id];
      }*/


      if (mergedPricelistPrices !== undefined && mergedPricelistPrices.length > 0) {
        const key = this.filterService.findArrayMultiKey(mergedPricelistPrices, 'product_uuid', product.id);

        if (key !== -1 && mergedPricelistPrices[key].price) {
          if (typeof mergedPricelistPrices[key].price === 'number') {
            pricePerUnit = mergedPricelistPrices[key].price;
          }
          if (typeof mergedPricelistPrices[key].price === 'object') {
            pricePerUnit = mergedPricelistPrices[key].price.usual_price;
            priceChangesProduct = this.__applyCustomPricingProduct(product, mergedPricelistPrices[key].price, shipment);
          }
        } else {
          if (localStorage.getItem(shipment_id + '-' + product.id)) {
            pricePerUnit = +localStorage.getItem(shipment_id + '-' + product.id);
          }
        }
      } else {
        if (localStorage.getItem(shipment_id + '-' + product.id)) {
          pricePerUnit = +localStorage.getItem(shipment_id + '-' + product.id);
        }
      }


      if (pricePerUnit === null || pricePerUnit === undefined) {
        this.calculation[shipment_id].internal_logic.products.push({
          uuid: product.id,
          name: product.name,
          count: product.amount,
          start_price_per_unit: null,
          start_price: null,
          price_changes: priceChangesProduct
        });
        failedProductPrices = true;
      } else {
        this.calculation[shipment_id].internal_logic.products.push({
          uuid: product.id,
          name: product.name,
          count: product.amount,
          start_price_per_unit: pricePerUnit,
          start_price: pricePerUnit * +product.amount,
          price_changes: priceChangesProduct
        });

      }

      /* reset for our next product */
      priceChangesProduct = [];

      if (shipment.shipment_code === 20272425) {
        console.log(failedProductPrices);
        console.log(pricePerUnit);
      }

      /* run this before pricerules, otherwise products will have no price when there are no pricerules present */
      this.getProductSum(shipment_id);

    });

    if (failedProductPrices) {
      this.calculation[shipment_id].success = false;
      return this.calculation[shipment_id];
    }

    /* foreach pricerules */
    /*
    * first check: does this pricerule (in iteration) apply for this client?
    * second check: match rules
    *
    * */
    let pricerulesMatched = [];
    if (this.pricerules !== undefined && this.pricerules.length > 1) {
      this.pricerules.forEach((pricerule, pricerule_index, pricerules) => {
        pricerule_index = pricerule.__document__key;
        /* client check */
        if (pricerule.match_contacts === 2) {
          if (!pricerule.match_contacts_arr.includes(shipment.contact_id)) {
            return;
          }
        }
        if (pricerule.match_contacts === 3) {
          if (pricerule.match_contacts_arr.includes(shipment.contact_id)) {
            return;
          }
        }

        const toPush = {...pricerule, ...new PriceRuleApplied()}
        this.calculation[shipment_id].internal_logic.pricerules_applied
          [pricerule.__document__key] = toPush;

        /* check every matchRule */
        pricerule.match_rules_arr.forEach((matchrule_id) => {
          /* find matchRule */
          const key = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', matchrule_id);
          /* log matchRule */
          this.calculation[shipment_id].internal_logic.pricerules_applied
            [pricerule.__document__key].matchRules.push(this.rulematches[key]);
          /* check matchRule */
          const result = this.matchRulesService.checkMatchRule(this.rulematches[key], shipment);
          /* log matchRule result */
          this.calculation[shipment_id].internal_logic.pricerules_applied
            [pricerule.__document__key].matchRulesMatched.push(result);
        });

        /* should match all matchRules */
        if (pricerule.match_all === 1) {
          if (!this.calculation[shipment_id].internal_logic.pricerules_applied
              [pricerule.__document__key].matchRulesMatched
              .includes(true) ||
            this.calculation[shipment_id].internal_logic.pricerules_applied
              [pricerule.__document__key].matchRulesMatched
              .includes(false)
          ) {
            return;
          }
        }

        /* should match at least one matchRule */
        if (pricerule.match_all === 2) {
          if (!this.calculation[shipment_id].internal_logic.pricerules_applied[pricerule_index].matchRulesMatched
            .includes(true)
          ) {
            return;
          }
        }

        /* should match only one of the matchRules */
        if (pricerule.match_all === 4) {
          if ((this.filterService.countInArray
            (this.calculation[shipment_id].internal_logic.pricerules_applied[pricerule_index].matchRulesMatched, true))
            < 2) {
            return;
          }
        }

        /* always proceed when match_all is 6 */
        if (pricerule.match_all === 6) {
        }

        pricerulesMatched.push({
          __document__key: pricerule.__document__key,
          pricechange_arr: pricerule.pricechange_arr
        });

      });

      /* time for pricerule priceChanges [that apply to products etc] */
      pricerulesMatched.forEach((pricerule_match, pricerule_match_key) => {
        pricerule_match.pricechange_arr.forEach((pricechange_id) => {
          /* check priceChange rules */
          const key = this.filterService.findArrayMultiKey(this.pricingchanges, '__document__key', pricechange_id);
          let exit;
          if (this.pricingchanges[key].change_per === 'product' || this.pricingchanges[key].change_per === 'extra_product') {
            const matchRulesMatchedForPriceChangeArr = [];
            this.pricingchanges[key].rules.forEach((changeRule) => {
              const ruleMatchKey = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', changeRule);

              if (this.shouldMatchRulePriceChangeBeCheckedHere(this.rulematches[ruleMatchKey])) {
                const result = this.matchRulesService.checkMatchRule(this.rulematches[ruleMatchKey], shipment);

                this.calculation[shipment_id].internal_logic.pricerules_applied
                  [pricerule_match.__document__key].priceChangesRules.push({
                  pricechange_id: pricechange_id,
                  matchRule_name: this.rulematches[ruleMatchKey].name,
                  matchRule_id: changeRule,
                  matched: result
                });

                matchRulesMatchedForPriceChangeArr.push(result);
              } else {
                 matchRulesMatchedForPriceChangeArr.push(true);
              }
            });


            if (this.checkMatchRulesForPriceChange(matchRulesMatchedForPriceChangeArr, this.pricingchanges[key].rules)) {
              this.applyPriceChange(this.pricingchanges[key], shipment_id, shipment);
            } else {
              return;
            }

          }
        });
      });


      /* recalculate because of possible product price changes */
      this.calculation[shipment_id].internal_logic.total_products_price = 0;
      this.getProductSum(shipment_id);

      /* time for pricerule priceChanges [that apply to shipments ] */
      pricerulesMatched.forEach((pricerule_match, pricerule_match_key) => {
        /*console.log(pricerule_match.pricechange_arr);*/
        pricerule_match.pricechange_arr.forEach((pricechange_key) => {
          /* check priceChange rules */
          const key = this.filterService.findArrayMultiKey(this.pricingchanges, '__document__key', pricechange_key);
          let exit;
          if (this.pricingchanges[key].change_per === 'shipment') {
            const matchRulesMatchedForPriceChangeArr = [];
            this.pricingchanges[key].rules.forEach((changeRule) => {
              const ruleMatchKey = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', changeRule);
              const result = this.matchRulesService.checkMatchRule(this.rulematches[ruleMatchKey], shipment);

              this.calculation[shipment_id].internal_logic.pricerules_applied
                [pricerule_match.__document__key].priceChangesRules.push({
                pricechange_id: pricechange_key,
                matchRule_name: this.rulematches[ruleMatchKey].name,
                matchRule_id: changeRule,
                matched: result
              });

              matchRulesMatchedForPriceChangeArr.push(result);
            });

            if (this.checkMatchRulesForPriceChange(matchRulesMatchedForPriceChangeArr, this.pricingchanges[key].rules)) {
              this.applyPriceChange(this.pricingchanges[key], shipment_id, shipment);
            } else {
              return;
            }
          }
        });
      });

      /* time for pricerule priceChanges [that apply to products (after all rules) ] */
      pricerulesMatched.forEach((pricerule_match, pricerule_match_key) => {
        pricerule_match.pricechange_arr.forEach((pricechange_key) => {
          /* check priceChange rules */
          const key = this.filterService.findArrayMultiKey(this.pricingchanges, '__document__key', pricechange_key);
          let exit;
          if (this.pricingchanges[key].change_per === 'products') {
            const matchRulesMatchedForPriceChangeArr = [];
            this.pricingchanges[key].rules.forEach((changeRule) => {
              const ruleMatchKey = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', changeRule);
              const result = this.matchRulesService.checkMatchRule(this.rulematches[ruleMatchKey], shipment);


              this.calculation[shipment_id].internal_logic.pricerules_applied
                [pricerule_match.__document__key].priceChangesRules.push({
                pricechange_id: pricechange_key,
                matchRule_name: this.rulematches[ruleMatchKey].name,
                matchRule_id: changeRule,
                matched: result
              });

              matchRulesMatchedForPriceChangeArr.push(result);
            });

            if (this.checkMatchRulesForPriceChange(matchRulesMatchedForPriceChangeArr, this.pricingchanges[key].rules)) {
              this.applyPriceChange(this.pricingchanges[key], shipment_id, shipment);
            } else {
              return;
            }
          }
        });
      });
    }

    this.getShipmentSum(shipment_id);

    /* TODO: implementeren dat 0 euro shipments geaccepteerd wrodt */
    this.calculation[shipment_id].internal_logic.total_shipment_price =
      this.calculation[shipment_id].internal_logic.total_products_price +
      this.calculation[shipment_id].internal_logic.total_shipment_charges;

    this.calculation[shipment_id].internal_logic.total_shipment_price =
      +this.calculation[shipment_id].internal_logic.total_shipment_price.toFixed(2);

    if (this.calculation[shipment_id].internal_logic.total_shipment_price !== 0) {
      this.calculation[shipment_id].success = true;
      return this.calculation[shipment_id];
    } else {
      this.calculation[shipment_id].success = false;
      return this.calculation[shipment_id];
    }

  }

  getProductSum(shipment_id) {
    this.calculation[shipment_id].internal_logic.products.forEach((product, index) => {
      if (product.price_changes === undefined || product.price_changes.length === 0) {
        this.calculation[shipment_id].internal_logic.total_products_price =
          this.calculation[shipment_id].internal_logic.total_products_price +
          product.start_price;
      } else {
        this.calculation[shipment_id].internal_logic.total_products_price =
          this.calculation[shipment_id].internal_logic.total_products_price +
          product.price_changes[product.price_changes.length - 1].price;
      }
    });
  }

  searchFixedPrice(shipment) {
    let fixedPrice: any = false;
    if (localStorage.getItem(shipment.__document__key + '-fixed')) {
      fixedPrice = +localStorage.getItem(
        shipment.__document__key + '-fixed');
    }
    if (shipment.labels !== undefined && shipment.labels.length > 0) {
      shipment.labels.forEach((label, index) => {
        if (label.tag === 'fixed_price') {
          fixedPrice = label.data;
        }
      });
    }
    return fixedPrice;
  }

  getShipmentSum(shipment_id) {
    const productPrice = this.calculation[shipment_id].internal_logic.total_products_price;

    if (this.calculation[shipment_id].internal_logic.shipment_charges
      && this.calculation[shipment_id].internal_logic.shipment_charges.length > 0) {
      this.calculation[shipment_id].internal_logic.shipment_charges.forEach((charge, index) => {
        this.calculation[shipment_id].internal_logic.total_shipment_charges =
          this.calculation[shipment_id].internal_logic.total_shipment_charges +
          charge.price;
      });
    } else {
      this.calculation[shipment_id].internal_logic.total_shipment_charges = 0;
    }

    if (this.calculation[shipment_id].internal_logic.after_price_changes
      && this.calculation[shipment_id].internal_logic.after_price_changes.length > 0) {
      this.calculation[shipment_id].internal_logic.after_price_changes.forEach((charge, index) => {
        this.calculation[shipment_id].internal_logic.total_shipment_charges =
          this.calculation[shipment_id].internal_logic.total_shipment_charges +
          charge.price;
      });
    } else {
      if (typeof this.calculation[shipment_id].internal_logic.total_shipment_charges !== 'number') {
        this.calculation[shipment_id].internal_logic.total_shipment_charges = 0;
      }
    }
  }

  /* TODO: should check for pricechange rules here and not before.. */
  applyPriceChange(priceChange, shipment_id, shipment = null) {
    if (priceChange.change_per === 'shipment') {
      if (priceChange.change_type === '%') {
        let price = 0;

        price = this.recalculateTotal(shipment_id) / 100 * priceChange.change;

        if (priceChange.change === '{fuel_surcharge}') {
          const fuelSurchargePercent = environment.currentLicenseholder.fuel_surcharge_percent;
          price = this.recalculateTotal(shipment_id) / 100 * fuelSurchargePercent;
        }


        this.calculation[shipment_id].internal_logic.shipment_charges.push({
          short_name: 'extra_charge',
          visible_name: priceChange.name_for_users,
          price: price,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === '-EUR') {
        this.calculation[shipment_id].internal_logic.shipment_charges.push({
          short_name: 'rebate',
          visible_name: priceChange.name_for_users,
          price: -priceChange.change,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === '+EUR') {
        this.calculation[shipment_id].internal_logic.shipment_charges.push({
          short_name: 'extra_charge',
          visible_name: priceChange.name_for_users,
          price: +priceChange.change,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === 'fixed_price') {
        this.calculation[shipment_id].internal_logic.after_price_changes.push({
          short_name: 'fixed_price',
          visible_name: priceChange.name_for_users,
          price: +priceChange.change,
          change_type: priceChange.change_type,
          change: null
        });
      }
    }

    if (priceChange.change_per === 'product') {
      /* check for priceChange rules  */

      /* check each product and check if pricechange needs to be applied */
      this.calculation[shipment_id].internal_logic.products.forEach((product, p_key) => {
        let exit;
        priceChange.rules.forEach((changeRule) => {
          const ruleMatchKey = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', changeRule);
          const result = this.matchRulesService.checkMatchRule(this.rulematches[ruleMatchKey], shipment);
          exit = (!result);
        });



        /* exit current iteration because rule didnt match */
        if (exit === true) {
          return;
        }

        let latestPrice = 0;
        if (this.calculation[shipment_id].internal_logic.products[p_key].price_changes.length > 0) {
          latestPrice = this.calculation[shipment_id].internal_logic.products[p_key].price_changes
            [this.calculation[shipment_id].internal_logic.products[p_key].price_changes.length - 1].price;
        } else {
          latestPrice = this.calculation[shipment_id].internal_logic.products[p_key].start_price;
        }


        if (priceChange.change_type === '%') {
          latestPrice = latestPrice / 100 * +priceChange.change;
          this.calculation[shipment_id].internal_logic.products[p_key].price_changes.push({
            short_name: 'percent_change',
            visible_name: priceChange.name_for_users,
            price: latestPrice,
            change_type: priceChange.change_type,
            change: priceChange.change
          });
        }
        if (priceChange.change_type === '-EUR') {
          latestPrice = latestPrice - priceChange.change;
          this.calculation[shipment_id].internal_logic.products[p_key].price_changes.push({
            short_name: 'rebate',
            visible_name: priceChange.name_for_users,
            price: latestPrice,
            change_type: priceChange.change_type,
            change: priceChange.change
          });
        }
        if (priceChange.change_type === '+EUR') {
          latestPrice = latestPrice + priceChange.change;
          this.calculation[shipment_id].internal_logic.products[p_key].price_changes.push({
            short_name: 'extra_charge',
            visible_name: priceChange.name_for_users,
            price: latestPrice,
            change_type: priceChange.change_type,
            change: priceChange.change
          });
        }
        if (priceChange.change_type === 'fixed_price') {
          this.calculation[shipment_id].internal_logic.products[p_key].price_changes.push({
            short_name: 'extra_charge',
            visible_name: priceChange.name_for_users,
            price: +priceChange.change,
            change_type: priceChange.change_type,
            change: priceChange.change
          });
        }
      });

    }

    if (priceChange.change_per === 'extra_product') {

      const newProductsArray = [];
      this.calculation[shipment_id].internal_logic.products.forEach((product, p_key) => {
        for (let i = 0; i < product.count; i++) {
          const product_i = {...JSON.parse(JSON.stringify(product))};
          product_i.count = 1;
          product_i.start_price = product.start_price_per_unit;
          /** needed to differentiate between same products but different productlines */
          product_i.uuid_key = product.uuid + '_' + p_key;
          newProductsArray.push(product_i);
        }
      });


      /** sort by price desc */
      newProductsArray.sort((a, b) => (a.start_price_per_unit < b.start_price_per_unit) ? 1 : -1);

      /*      console.log(this.calculation[shipment_id]);*/
       /*console.log(newProductsArray);*/


      newProductsArray.forEach((product, product_key) => {
        if (product_key > 0) {
          let exit;
          priceChange.rules.forEach((changeRule) => {
            const ruleMatchKey = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', changeRule);

            const result = this.matchRulesService.checkMatchRule(this.rulematches[ruleMatchKey], shipment, product);
            exit = (!result);
          });

          /** exit current iteration because rule didnt match */
          if (exit === true) {
            return;
          }

          let latestPrice = 0;
          if (newProductsArray[product_key].price_changes.length > 0) {
            latestPrice = newProductsArray[product_key].price_changes
              [newProductsArray[product_key].price_changes.length - 1].price;
          } else {
            latestPrice = newProductsArray[product_key].start_price_per_unit;
          }

          if (priceChange.change_type === '%') {
            latestPrice = (latestPrice / 100 * priceChange.change);
            newProductsArray[product_key].price_changes.push({
              short_name: 'percent_change',
              visible_name: priceChange.name_for_users,
              price: latestPrice,
              change_type: priceChange.change_type,
              change: priceChange.change
            });
          }
          if (priceChange.change_type === '-EUR') {
            latestPrice = latestPrice - priceChange.change;
            newProductsArray[product_key].price_changes.push({
              short_name: 'rebate',
              visible_name: priceChange.name_for_users,
              price: latestPrice,
              change_type: priceChange.change_type,
              change: priceChange.change
            });
          }
          if (priceChange.change_type === '+EUR') {
            latestPrice = latestPrice + priceChange.change;
            newProductsArray[product_key].price_changes.push({
              short_name: 'extra_charge',
              visible_name: priceChange.name_for_users,
              price: latestPrice,
              change_type: priceChange.change_type,
              change: priceChange.change
            });
          }
          if (priceChange.change_type === 'fixed_price') {
            newProductsArray[product_key].price_changes.push({
              short_name: 'extra_charge',
              visible_name: priceChange.name_for_users,
              price: priceChange.change,
              change_type: priceChange.change_type,
              change: priceChange.change
            });
          }
        }
      });


      /* apply extra product changes to this.calculation */
      this.calculation[shipment_id].internal_logic.products.forEach((product, product_key) => {
        product.uuid_key = product.uuid + '_' + product_key;
        /* find product in newProductsArray to retrieve price changes */
        let newPriceProduct = 0;
        newProductsArray.forEach((new_product, new_product_key) => {
          /** if quantity is higher than 1, we must combine productprice, otherwise we dont */
            if (product.count > 1) {
              if (new_product.uuid_key === product.uuid_key && new_product.price_changes[new_product.price_changes.length - 1]) {
                newPriceProduct += new_product.price_changes[new_product.price_changes.length - 1].price;
              } else if (new_product.uuid_key === product.uuid_key) {
                newPriceProduct += new_product.start_price;
              }
            } else {
              if (new_product.uuid_key === product.uuid_key && new_product.price_changes[new_product.price_changes.length - 1]) {
                newPriceProduct = new_product.price_changes[new_product.price_changes.length - 1].price;
              } else if (new_product.uuid_key === product.uuid_key) {
                newPriceProduct = new_product.start_price;
              }
            }
        });

        if (this.calculation[shipment_id].internal_logic.products[product_key].start_price !== newPriceProduct) {
          if (newPriceProduct !== null && newPriceProduct !== 0) {
            this.calculation[shipment_id].internal_logic.products[product_key].price_changes.push({
              short_name: 'extra_product_change',
              visible_name: priceChange.name_for_users,
              price: newPriceProduct,
              change_type: priceChange.change_type,
              change: priceChange.change
            });
          }
        }

      });

    }

    if (priceChange.change_per === 'products') {
      if (priceChange.change_type === '%') {
        let price = 0;

        price = this.calculation[shipment_id].internal_logic.total_products_price / 100 * priceChange.change;

        if (priceChange.change === '{fuel_surcharge}') {
          const fuelSurchargePercent = environment.currentLicenseholder.fuel_surcharge_percent;
          price = this.calculation[shipment_id].internal_logic.total_products_price / 100 * fuelSurchargePercent;
        }

        this.calculation[shipment_id].internal_logic.after_price_changes.push({
          short_name: 'extra_charge',
          visible_name: priceChange.name_for_users,
          price: price,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === '-EUR') {
        this.calculation[shipment_id].internal_logic.after_price_changes.push({
          short_name: 'rebate',
          visible_name: priceChange.name_for_users,
          price: -priceChange.change,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === '+EUR') {
        this.calculation[shipment_id].internal_logic.after_price_changes.push({
          short_name: 'extra_charge',
          visible_name: priceChange.name_for_users,
          price: priceChange.change,
          change_type: priceChange.change_type,
          change: priceChange.change
        });
      }
      if (priceChange.change_type === 'fixed_price') {
        this.calculation[shipment_id].internal_logic.after_price_changes.push({
          short_name: 'fixed_price',
          visible_name: priceChange.name_for_users,
          price: priceChange.change,
          change_type: priceChange.change_type,
          change: null
        });
      }
    }
  }

  __applyCustomPricingProduct(product, productPriceObject, shipment) {
    const priceChanges = [];
    let price = 0;
    let noChange = true;

    if (productPriceObject.user_prices && productPriceObject.user_prices.length > 0) {
      productPriceObject.user_prices.forEach((item) => {
        if (item.contact_ids.includes(shipment.contact_id)) {
          price = item.price;
          noChange = false;
        }
      });

    }
    if (productPriceObject.price_levels && productPriceObject.price_levels.length > 0) {
      /*  get contact ID price level */
      productPriceObject.price_levels.forEach((item) => {
        const contact = this.contactsService.getContacts(shipment.contact_id);
        if (contact.other && contact.other.PRICE_LEVEL && contact.other.PRICE_LEVEL === item.level) {
          price = item.price;
          noChange = false;
        }
      });
    }
    if (productPriceObject.promo_discounts && productPriceObject.promo_discounts.length > 0) {
      productPriceObject.promo_discounts.forEach((item) => {
        if (Math.floor(Date.now() / 1000) >= item.date_from && Math.floor(Date.now() / 1000) <= item.date_to) {
          price = item.price;
          noChange = false;
        }
      });

    }
    if (productPriceObject.bulk_one && productPriceObject.bulk_one.length > 0) {
      productPriceObject.bulk_one.forEach((item) => {
        if (product.amount >= item.quantity_from && product.amount <= item.quantity_to) {
          price = item.price;
          noChange = false;
        }
      });
    }
    if (productPriceObject.bulk_two && productPriceObject.bulk_two.length > 0) {
      const total_product_amount = product.amount;
      const all_product_prices = [];
      if (total_product_amount > 1) {
        productPriceObject.bulk_two.forEach((item) => {
          for (let x = 1; x <= total_product_amount; x++) {
            if (x >= item.quantity_from) {
              all_product_prices.push(item.price);
            } else {
              all_product_prices.push(productPriceObject.usual_price);
            }
          }


          /* calculate the average price because later in the script, price gets multiplied by quantity so we dont want total price */
          price = all_product_prices.reduce(function (sum, a) {
            return sum + a;
          }, 0) / (all_product_prices.length || 1);
          noChange = false;
        });
      }
    }

    /* no change happened */
    if (noChange === true) {
      return priceChanges;
    }

    price = price * product.amount;

    priceChanges.push({
      short_name: 'fixed_price',
      visible_name: 'Special Product Pricing',
      price: price,
      change_type: 'fixed_price',
      change: null
    });

    return priceChanges;
  }

  checkMatchRulesForPriceChange(arr, rules) {
    if (arr.length !== rules.length) {
      return false;
    }

    if (arr.includes(false)) {
      return false;
    }

    if (!arr.includes(true) && arr.length > 0) {
      return false;
    }

    return true;
  }

  shouldMatchRulePriceChangeBeCheckedHere(rule) {
    if (rule.type === 'specific_product' || rule.type === 'per_product') {
      return false;
    }
    return true;

  }

  recalculateTotal(shipment_id) {
    const productPrice = this.calculation[shipment_id].internal_logic.total_products_price;
    let totalShipment = 0;

    if (this.calculation[shipment_id].internal_logic.shipment_charges
      && this.calculation[shipment_id].internal_logic.shipment_charges.length > 0) {
      this.calculation[shipment_id].internal_logic.shipment_charges.forEach((charge, index) => {
        totalShipment +=
          charge.price;
      });
    } else {
      totalShipment = 0;
    }

    return productPrice + totalShipment;
  }

}
