import {NgForage, NgForageCache, NgForageConfig, CachedItem} from 'ngforage';
import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class LocalDataService {
/*  public dataChangedLD = new EventEmitter<[]>();*/

  constructor(private readonly ngf: NgForage,
              private readonly cache: NgForageCache
  ) {
/*   this.ngf.name = 'SENDNOW';
   this.cache.driver = Driver.INDEXED_DB;*/
/*    window.addEventListener('storage', this.storageEventHandler, false);*/
  }

  public getItem<T = any>(key: string): Promise<T> {
    return this.ngf.getItem<T>(key);
  }

  public setItem<T = any>(key: string, value: T): Promise<T> {
    window.localStorage.setItem('change', key + '-' + Date.now());
    return this.ngf.setItem<T>(key, value);
  }

  /* Cache time in milliseconds */
  public setCachedItem<T = any>(key: string, value: T, cacheTime: number): Promise<T> {
    return this.cache.setCached<T>(key, value, cacheTime);
  }

  public getCachedItem<T = any>(key: string): Promise<T | null> {
    return this.cache.getCached<T>(key)
      .then((r: CachedItem<T>) => {
        if (!r.hasData || r.expired) {
          return null;
        }

        return r.data;
      });
  }

/*  private storageEventHandler = (evt) => {
    console.log("oldValue: " + evt.oldValue );
    console.log("storage event called key: " + evt.key );
    console.log("newValue: " + evt.newValue );
    this.dataChangedLD.emit(evt.newValue);
  }*/
}
