import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';
import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import {RoutingState} from './shared/services/routingstate.service';
import {environment} from '../environments/environment';
import {AuthService} from './shared/services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {PricelevelService} from './shared/services/data-sync/pricelevel.service';
import {VersionCheckService} from './shared/services/version-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [VersionCheckService],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'SENDNOW';
  pageTitle = '';


  constructor(
    public title: Title,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private routingState: RoutingState,
    private authService: AuthService,
    private translate: TranslateService,
    private http: HttpClient,
    private versionCheckService: VersionCheckService
  ) { routingState.loadRouting();

    if (localStorage.getItem('sendnow-language')) {
      this.translate.use(localStorage.getItem('sendnow-language'));
    } else {
      translate.use('en');
      /* check browser language via server request, JS lang check doesnt work properly */
      this.http.get<any>(
        'https://get-headers.appspot.com/')
        .subscribe(response => {
          let language;
          // language = 'en';
          if (response['Accept-Language'].substr(0, 2) === 'en' || response['Accept-Language'].substr(0, 2) === 'nl') {
            language = response['Accept-Language'].substr(0, 2);
          } else {
            language = 'en';
          }
          translate.use(language);
        }, err => {
          console.log(err);
          const language = 'en';
          translate.use(language);
        });


      // Translator init
/*      const browserLang: string = translate.getBrowserLang();
      console.log(browserLang);
      console.log(navigator.language);
      translate.use(browserLang.match(/en|fr|nl/) ? browserLang : 'en');*/
    }



  }

  ngOnInit() {
    this.changePageTitle();
    this.versionCheckService.initVersionCheck('version.json');
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);

    // Google Analytics
    this.initGoogleAnalyticsPageView();
  }

  private initGoogleAnalyticsPageView() {
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        this.router.events.subscribe(event => {
          const ga = (window as any).ga;
          if (event instanceof NavigationEnd) {
            const tracker = ga.getAll()[0];
            tracker.set('page', event.urlAfterRedirects);
            tracker.send('pageview');
          }
        });
        clearInterval(interval);
      }
    }, 50);
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`; });
      this.translate.stream(this.pageTitle).subscribe((text: string) => {
/*
        this.pageTitle = text;
        this.pageTitle += ` | ${this.appTitle}`;
*/
        this.pageTitle = text;
        this.appTitle += ` | ${this.pageTitle}`;
        this.title.setTitle(this.appTitle);


      });

    });
  }

}
