import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ScrollService {
  scrollBottomReached = new Subject<boolean>();

  constructor () {

  }
}
