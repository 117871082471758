import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DataManagementService} from '../data-sync/data_management.service';

import {AngularFireAuth} from '@angular/fire/auth';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {TokenHolder} from './tokenholder';
import {FilterService} from '../filter.service';
import {ContactsService} from '../data-sync/contacts.service';
import {LicenseholderService} from '../data-sync/licenseholder.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {DateService} from '../date.service';

@Injectable()
export class AuthService {

  private apiUrl = environment.apiURL;
  private environment = environment;

  public currentAuthenticatedUser;
  public isAuthenticated = false;

  public authPromise: Promise<boolean>;

/*
  public _isAuthenticated = new BehaviorSubject(false);



  public _currentAuthenticatedUser = new Subject();

  public _authInitCompleted = new Subject();
  public authInitCompleted: boolean;*/

  constructor(private db: AngularFirestore, private http: HttpClient, private dataManagementService: DataManagementService,
              private tokenHolder: TokenHolder, private filterService: FilterService,
              public afAuth: AngularFireAuth,
              private licenseholderService: LicenseholderService,
              public dateService: DateService) {
    this.authPromise = new Promise((resolve, reject) => {
    afAuth.auth.onAuthStateChanged((user) => {

        if (user) {

          let userFromDb;

          this.db.collection<any>('environment/' + environment.name + '/users')
            .ref
            .where('username', '==', user.uid)
            .get()
            .then((querySnapshot) => {
              userFromDb = querySnapshot.docs[0].data();
              userFromDb.__document__key = querySnapshot.docs[0].id;

              environment.currentAuthUser = this.currentAuthenticatedUser = userFromDb;
              environment.licenseholder_id = userFromDb.licenseholder_id;
              /*
              this._currentAuthenticatedUser.next(this.currentAuthenticatedUser);*/

              let contact: any = {};
              let licenseholder: any = {};

              /* UPDATE LAST_LOGIN EVERY 15 minutes */
              this.updateLastLogin(querySnapshot.docs[0].id);
              const updaterLastLogin = setTimeout(() => {
                if (!document.hidden) {
                  this.updateLastLogin(querySnapshot.docs[0].id);
                }
              }, 900000);

              /* get contact */
              this.db.collection<any>('environment/' + environment.name + '/contacts')
                .doc(environment.currentAuthUser.contact_id)
                .ref
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    contact = doc.data();
                    contact.__document__key = doc.id;

                    environment.currentContact = contact;


                    this.db.collection<any>('environment/' + environment.name + '/licenseholders')
                      .doc(environment.licenseholder_id.toString())
                      .ref
                      .get()
                      .then((doc_licenseholder) => {
                        if (doc_licenseholder.exists) {
                          licenseholder = doc_licenseholder.data();
                          licenseholder.__document__key = doc_licenseholder.id;

                          environment.currentLicenseholder = licenseholder;

                          /* OK, application may load */
                          this.isAuthenticated = true;
                          resolve(this.isAuthenticated);
                        }
                      });
                  } else {
                    console.log('Error occurred: contact doesnt exist...');
                  }
                });
            });


          // User is signed in.
          const displayName = user.displayName;
          const email = user.email;
          const emailVerified = user.emailVerified;
          const uid = user.uid;
          /* const providerData = user.providerData; */
        } else {
          // User is signed out.
          this.isAuthenticated = false;
          resolve(this.isAuthenticated);
          /*
          this._isAuthenticated.next(false);*/
        }
      });
    });
  }

  logout() {
    this.dataManagementService.saveData('auth_token', null);
    this.afAuth.auth.signOut();
  }

  setToken(token: string) {
    this.tokenHolder.token = token;
    this.dataManagementService.saveData('auth_token', token);
  }

  updateLastLogin(user_id) {
    const usersRef = this.db
      .collection<any>('environment/' + environment.name + '/users')
      .doc(user_id);

    const getDoc = usersRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('Current user does not exist anymore... uh...');
      } else {
        return usersRef.update({
          last_login_date: this.dateService.getUnixTimestamp()
        });
      }
    });
  }

}
