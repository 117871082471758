import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import {AuthResolver} from './shared/resolvers/auth.resolver';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'shipment/add',
    pathMatch: 'full'
  },
  {
    path: 'index',
    redirectTo: 'shipment/add',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
    data: { title: 'Choose A Demo' }
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: AdminLayoutComponent,
/*    resolve: {
      auth: AuthResolver
    },*/
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'DASHBOARD', breadcrumb: 'DASHBOARD'}
      },
      {
        path: 'companies',
        loadChildren: () => import('./views/companies/companies.module').then(m => m.AppCompaniesModule),
        data: { title: 'CONTACTS', breadcrumb: 'CONTACTS'}
      },
      {
        path: 'licenseholder',
        loadChildren: () => import('./views/licenseholder/licenseholder.module').then(m => m.AppLicenseholderModule),
        data: { title: 'LICENSEHOLDER', breadcrumb: 'LICENSEHOLDER'}
      },
      {
        path: 'invoices',
        loadChildren: () => import('./views/invoices/invoices.module').then(m => m.AppInvoicesModule),
        data: { title: 'INVOICES', breadcrumb: 'INVOICES'}
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.AppUsersModule),
        data: { title: 'USERS', breadcrumb: 'USERS'}
      },
      {
        path: 'shipment',
        loadChildren: () => import('./views/shipmenteditor/shipmenteditor.module').then(m => m.AppShipmenteditorModule),
        data: { title: 'SHIPMENTS', breadcrumb: 'SHIPMENTS'}
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.AppSettingsModule),
        data: { title: 'Settings', breadcrumb: 'SETTINGS'}
      },
      {
        path: 'address',
        loadChildren: () => import('./views/addressmanagement/addressmanagement.module').then(m => m.AppAddressManagementModule),
        data: { title: 'ADDRESSES', breadcrumb: 'ADDRESSES'}
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.AppProductsModule),
        data: { title: 'PRODUCTS', breadcrumb: 'PRODUCTS'}
      },
      {
        path: 'material',
        loadChildren: () => import('./views/material/app-material.module').then(m => m.AppMaterialModule),
        data: { title: 'Material', breadcrumb: 'MATERIAL'}
      },
      {
        path: 'dialogs',
        loadChildren: () => import('./views/app-dialogs/app-dialogs.module').then(m => m.AppDialogsModule),
        data: { title: 'Dialogs', breadcrumb: 'DIALOGS'}
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE'}
      },
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/tables/tables.module').then(m => m.TablesModule),
        data: { title: 'Tables', breadcrumb: 'TABLES'}
      },
      {
        path: 'tour',
        loadChildren: () => import('./views/app-tour/app-tour.module').then(m => m.AppTourModule),
        data: { title: 'Tour', breadcrumb: 'TOUR'}
      },
      {
        path: 'forms',
        loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule),
        data: { title: 'Forms', breadcrumb: 'FORMS'}
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/charts/charts.module').then(m => m.AppChartsModule),
        data: { title: 'Charts', breadcrumb: 'CHARTS'}
      },
      {
        path: 'map',
        loadChildren: () => import('./views/map/map.module').then(m => m.AppMapModule),
        data: { title: 'Map', breadcrumb: 'MAP'}
      },
      {
        path: 'dragndrop',
        loadChildren: () => import('./views/dragndrop/dragndrop.module').then(m => m.DragndropModule),
        data: { title: 'Drag and Drop', breadcrumb: 'DND'}
      },
      {
        path: 'inbox',
        loadChildren: () => import('./views/app-inbox/app-inbox.module').then(m => m.AppInboxModule),
        data: { title: 'Inbox', breadcrumb: 'INBOX'}
      },
      {
        path: 'calendar',
        loadChildren: () => import('./views/app-calendar/app-calendar.module').then(m => m.AppCalendarModule),
        data: { title: 'Calendar', breadcrumb: 'CALENDAR'}
      },
      {
        path: 'chat',
        loadChildren: () => import('./views/app-chats/app-chats.module').then(m => m.AppChatsModule),
        data: { title: 'Chat', breadcrumb: 'CHAT'}
      },
      {
        path: 'cruds',
        loadChildren: () => import('./views/cruds/cruds.module').then(m => m.CrudsModule),
        data: { title: 'CRUDs', breadcrumb: 'CRUDs'}
      },
      {
        path: 'shop',
        loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule),
        data: { title: 'Shop', breadcrumb: 'SHOP'}
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/mat-icons/mat-icons.module').then(m => m.MatIconsModule),
        data: { title: 'Icons', breadcrumb: 'MATICONS'}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

