import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';

@Injectable()
export class PricelevelService {

  public price_levels: any[] = [];


  constructor(private db: AngularFirestore, private filterService: FilterService) {

    this.db.collection<any>('environment/' + environment.name + '/pricing_levels',
      ref => ref.where('licenseholder_id', '==', environment.licenseholder_id.toString()))
      .ref
      .get()
      .then((querySnapshot) => {

        querySnapshot.docs.forEach((doc) => {
          this.price_levels.push({__document__key: doc.id, ...doc.data()});
        });
      });

  }


}
