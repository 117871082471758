import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {DataManagementService} from '../data-sync/data_management.service';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = false; // Set this value dynamically

  constructor(public router: Router, private dataManagementService: DataManagementService,
              private authService: AuthService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /* get authentication state from auth service */
    const [serviceAuthenticated, token] = await Promise.all([this.authService.authPromise, this.dataManagementService.getData('auth_token')]);
    if (token && token.length > 0) {
      this.isAuthenticated = true;
      return this.isAuthenticated;
    } else {
      this.router.navigate(['/sessions/signin']);
      return false;
    }


  }
}
