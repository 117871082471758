import {Address} from '../../models/address.model';
import {EventEmitter, Injectable} from '@angular/core';
import {DataManagementService} from './data_management.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {FilterService} from '../filter.service';
import {Contact} from '../../models/contact.model';
import {Pricerule} from '../../models/pricerule.model';
import {PermissionService} from './permission.service';

@Injectable()
export class AddressService {
  public addressesChanged = new EventEmitter<Address[]>();
  public addresses: Address[] = [];
  public addressesRef: AngularFirestoreCollection<any>;
  public addressesObserve$: Observable<any>;

  public addressesListener = new BehaviorSubject([]);

/*  addresses_preset: Address[] = [
    {
      id: 1,
      name: 'Calvin Uijlen',
      street: 'Boerhaavelaan',
      housenumber: 7,
      housenumberadd: null,
      postalcode: '2713HA',
      city: 'Zoetermeer',
      country: 'NL',
      email: 'calvinturbo@gmail.com',
      phone_number: '0610110033',
      default: 1,
      type: 'private'
    }
  ];*/

  constructor (private db: AngularFirestore, private filterService: FilterService,
               private dataManagementService: DataManagementService, private permissionService: PermissionService) {
    if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
      this.addressesRef = db.collection<any>('environment/' + environment.name + '/addresses',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id));
    } else {
      this.addressesRef = db.collection<any>('environment/' + environment.name + '/addresses',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
          .where('contact_id', '==', environment.currentAuthUser.contact_id));
    }

    this.addressesObserve$ = this.addressesRef.snapshotChanges().pipe(
      tap (t => {
        this.addresses = [];
      }),
      map(actions => actions.map(address => {

        let doc_data = address.payload.doc.data();
        doc_data['__document__key'] = address.payload.doc.id;

        if (address['type'] === 'added' || 'modified') {
          this.addresses.push(doc_data);
        }

        return address;
      }))
    );

    this.addressesObserve$.subscribe(() => {
      this.addressesListener.next([Date.now()]);
    });
  }

  addAddress(address: Address) {
    const address_c = {...address};
    delete address_c.__document__key;
    return this.addressesRef.add(address_c);
  }

  updateAddress(address_id: string, address: any) {
    const addressRef = this.db
      .collection<any>('environment/' + environment.name + '/addresses')
      .doc(address_id);

    const getDoc = addressRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(address);

        return addressRef.update(address);
      }
    });

    return getDoc;
  }

  removeAddress(address_id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/addresses').doc(address_id).delete();
  }


  getAddress(id: string) {
    const key = this.filterService.findArrayMultiKey(this.addresses, '__document__key', id);
    return this.addresses[key];
  }




}
