export class DefinedInfo {
  /** TODO moet roles zijn en niet permissions, plus dubbel vanwege permission.model.ts */
  permissions = [
    {
      value: 'FULL_USER',
      name: 'Full User Rights'
    },
    {
      value: 'FULL_ADMIN',
      name: 'Full Admin Rights'
    },
    {
      value: 'ADMIN_SHIPMENTS',
      name: 'Admin: shipment management'
    },
    {
      value: 'ADMIN_ADMINISTRATION',
      name: 'Admin: administration'
    }
  ];

  rule_match_types = [
    'shipment', // shipment specific things like return shipment
    'specific_product',
    'count_total_products',
    'per_product',
    'from_address',
    'to_address',
    'both_addresses',
    'label',
    'contact'
  ];

  operators = [
    '>',
    '<',
    '>=',
    '<=',
    '=',
    '!=',
    'CON',
    'N_EMPTY',
    'IN_RANGE',
    'IN_ARR',
    'NOT_IN_ARR',
    'IN_ARR_RANGE',
    'IN_ARR_RANGE_PC'
  ];

  rule_match_columns = {
    'shipment': [],
    'label': [],
    'specific_product': [],
    'count_total_products': [],
    'per_product': [
      'rembours',
      'weight'
    ],
    'from_address': [
      'from_company_name',
      'from_country',
      'from_phone_number',
      'from_postalcode',
      'from_street',
      'from_housenumber',
      'from_housenumberadd',
      'from_address',
      'from_city',
      'from_email',
      'from_type',
      'from_postalcodecountry'
    ],
    'to_address': [
      'to_company_name',
      'to_country',
      'to_phone_number',
      'to_postalcode',
      'to_street',
      'to_housenumber',
      'to_housenumberadd',
      'to_address',
      'to_city',
      'to_email',
      'to_type',
      'to_postalcodecountry'
    ],
    'both_addresses': [
      'company_name',
      'country',
      'phone_number',
      'postalcode',
      'street',
      'housenumber',
      'housenumberadd',
      'address',
      'city',
      'email',
      'type',
      'postalcodecountry'
    ],
    'contact': [
      'contact_id'
    ]
  };

  pricechange_type = [
    '%',
    '-EUR',
    '+EUR',
    'fixed_price'
  ];

  pricechange_per = [
    'shipment',
    'product',
    'extra_product',
    'products' // fuel surcharge uses this
  ];


  contact_types: any = [
    {
      value: 1,
      name: 'Customer'
    },
    {
      value: 2,
      name: 'Other'
    }
  ];

  shipment_status = [
    {
      value: 1,
      name: 'Pending'
    },
    {
      value: 2,
      name: 'Cancelled'
    },
    {
      value: 3,
      name: 'Invoiced'
    },
    {
      value: 4,
      name: 'Retrieved'
    },
    {
      value: 5,
      name: 'Delivered'
    },
    {
      value: 6,
      name: 'Plannedforroute'
    },
    {
      value: 7,
      name: 'OnRoute'
    },
    {
      value: 9,
      name: 'Third-party'
    },
  ];

  /*
    {
      value: 8,
      name: 'Concept'
    },*/

  tax = [
    {
      value: 1,
      name: '0% VAT',
      calculate_with: 0,
      system_logic_name: 'tax_0'
    },
    {
      value: 2,
      name: '9% VAT',
      calculate_with: 9,
      system_logic_name: 'tax_9'
    },
    {
      value: 3,
      name: '19% VAT',
      calculate_with: 19,
      system_logic_name: 'tax_19'
    },
    {
      value: 4,
      name: '21% VAT',
      calculate_with: 21,
      system_logic_name: 'tax_21'
    }
  ];

  shipment_types = [
    {
      value: 'day',
      name: 'Day',
      system_logic_name: 'day_shipment'
    },
    {
      value: 'night',
      name: 'Night',
      system_logic_name: 'night_shipment'
    },
    {
      value: 'saturday',
      name: 'Saturday',
      system_logic_name: 'saturday_shipment'
    },
    {
      value: 'sunday',
      name: 'Sunday',
      system_logic_name: 'sunday_shipment'
    }
  ];

  document_types = [
    {
      value: 1,
      name: 'pickup'
    },
    {
      value: 2,
      name: 'delivery'
    },
    {
      value: 3,
      name: 'notes'
    },
    {
      value: 4,
      name: 'invoice'
    },
    {
      value: 5,
      name: 'overview-invoice'
    }
  ];

  csv_integrations = {
    'logiparcel': [
      'Name',
      'Contact',
      'Street',
      'Street number',
      'Address',
      'Street 2',
      'Postal Code',
      'City',
      'Country',
      'Phone',
      'E-mail',
      'Product Code',
      'Reference Number',
      'Weight in kg (max. 3 digits)',
      'COD amount',
      'COD Currency',
      'Quantity',
      'Sender Address',
      'Length',
      'Width',
      'Heigh',
      'Price',
      'Goods Type',
      'Goods Description',
      'Currency',
      'ShopID',
      'StateCode',
      'Saturday Delivery'
    ]
  };

}
