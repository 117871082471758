import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class IntegrationService {
  constructor () {}

  logiparcelCsvExport(shipment, object) {

    object.Name = shipment.address_to.company_name;
    object.Contact = shipment.address_to.person_name;
    /* TODO remove house number and extension */
    object.Street = shipment.address_to.address;
    object['Street number'] = null;
    object['Address'] = shipment.address_to.address;
    object['Street 2'] = null;
    object['Postal Code'] = shipment.address_to.postalcode;
    object['City'] = shipment.address_to.city;
    object['Country'] = shipment.address_to.country;
    object['Phone'] = shipment.address_to.phone_number;
    object['E-mail'] = shipment.address_to.email;
    object['Product Code']  = null;
    object['Reference Number'] = null;
    object['Weight in kg (max. 3 digits)'] = shipment.products.reduce((a, b) => +a + +b.weight, 0);
    object['COD amount'] = shipment.products.reduce((a, b) => +a + +b.rembours, 0);
    object['COD Currency'] = 'EUR';
    object['Quantity'] = shipment.products.reduce((a, b) => +a + +b.amount, 0);
    object['Sender Address'] = null;
    object['Length'] = null;
    object['Heigh'] = null;
    object['Price'] = null;
    object['Goods Type'] = null;
    object['Goods Description'] = null;
    object['Currency'] = 'EUR';
    object['ShopID'] = null;
    object['StateCode'] = null;
    object['Saturday Delivery'] = false;

    return object;
  }
}
