import {Pricerule} from './pricerule.model';
import {Shipment} from './shipment.model';

export class PriceRuleApplied extends Pricerule {
  public matchRules: any[] = [];
  public matchRulesMatched: boolean[] = [];
  public priceChangesRules: any[] = [];
}

export class PriceChangeCalculation {
  short_name: string;
  visible_name: string;
  price: number;
  change_type: string;
  change: string | number;
}

export class ProductsCalculation {
  uuid: string;
  uuid_key?: string; /** uuid _ productkey */
  name: string;
  count: number;
  start_price_per_unit: number;
  start_price: number;
  price_changes: PriceChangeCalculation[];
}

export class CalculationInternalLogic {
  public products: ProductsCalculation[] = [];
  public total_products_price = 0;
  public shipment_charges: PriceChangeCalculation[] = [];
  public total_shipment_charges = 0;
  public after_price_changes: PriceChangeCalculation[] = [];
  public total_shipment_price = 0;
  public pricerules_applied: PriceRuleApplied[] = [];
}

export class Calculation {
  public __document__key: string | null;
  public _addedon: number | null;
  public _updatedon: number | null;

  public licenseholder_id: string | null;
  public shipment_id: string | null;
  public user_id: string | null;
  public shipment_code: number | null;

  public internal_logic: CalculationInternalLogic = new CalculationInternalLogic();
  public external_logic: any[];

  public error?: null | string;

  public shipment: Shipment;

  public success: boolean;
}
