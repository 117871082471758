import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {DataManagementService} from './data_management.service';
import {ApiService} from './api.service';
import {Shipment} from '../../models/shipment.model';
import {Contact} from '../../models/contact.model';
import {FilterService} from '../filter.service';
import {UserService} from './user.service';
import {PermissionService} from './permission.service';

@Injectable()
export class ContactsService {

  public contacts: Contact[] = [];
  public contactsRef: AngularFirestoreCollection<any>;
  public contactsObserve$: Observable<any>;

  activeContact: string | null = null;

  public contactsListener = new BehaviorSubject([]);


  constructor(private db: AngularFirestore, private filterService: FilterService, private userService: UserService,
              public permissionService: PermissionService) {
      this.contactsRef = db.collection<any>('environment/' + environment.name + '/contacts',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
      );

    this.contactsObserve$ = this.contactsRef.snapshotChanges().pipe(
      tap (t => {
        this.contacts = [];
      }),
      map(actions => actions.map(contact => {

        let doc_data = contact.payload.doc.data();
        doc_data['__document__key'] = contact.payload.doc.id;

        if (doc_data.hidden === null || doc_data.hidden === undefined) {
          doc_data.hidden = false;
        }

        if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
          if (contact['type'] === 'added' || 'modified') {
            this.contacts.push(doc_data);
          }
        } else {
          if (doc_data['__document__key'] === environment.currentAuthUser.contact_id) {
            this.contacts.push(doc_data);
          }
        }

        return contact;
      }))
    );

    this.contactsObserve$.subscribe(() => {
      this.contactsListener.next([Date.now()]);
    });
  }

  addContact(contact: any) {
    const contact_c = {...contact};
    delete contact_c.__document__key;
    contact_c.hidden = false;

    return this.contactsRef.add(contact_c);
  }

  updateContact(contact_id: string, contact: any) {
    const contact_c = {...contact};
    delete contact_c.__document__key;

    const contactsRef = this.db
      .collection<any>('environment/' + environment.name + '/contacts')
      .doc(contact_id);

    const getDoc = contactsRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log(contact_c);

        return contactsRef.update(contact_c);
      }
    });

    return getDoc;
  }


  getContacts(id: string) {
    const key = this.filterService.findArrayMultiKey(this.contacts, '__document__key', id);
    return this.contacts[key];
  }

  removeContact(contact_id: string) {
    const contactsRef = this.db
      .collection<any>('environment/' + environment.name + '/contacts')
      .doc(contact_id);

    const getDoc = contactsRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());

        const contact = {
          hidden: true
        };

        return contactsRef.update(contact);
      }
    });

    const usersToDelete = this.filterService.filterArrayByKeyValue(this.userService.users, 'contact_id', contact_id);
    usersToDelete.forEach((user) => {
      const userRef = this.db
        .collection<any>('environment/' + environment.name + '/users')
        .doc(user.__document__key)
        .delete();
    });


    return getDoc;
  }


}
