import {Product} from '../../models/productsn.model';
import {EventEmitter, Injectable} from '@angular/core';
import {DataManagementService} from './data_management.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contact} from '../../models/contact.model';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';
import {PermissionService} from './permission.service';

declare function require(name: string);

const uuidv4 = require('uuid/v4');

@Injectable()
export class ProductService {
  public productsChanged = new EventEmitter<Product[]>();
  public products: Product[] = [];

  public productsListener = new BehaviorSubject([]);

  public productlists: any[] = [];
  public productlistsRef: AngularFirestoreCollection<any>;
  public productlistsObserve$: Observable<any>;

  public pricelists: any[] = [];

  constructor (private dataManagementService: DataManagementService,
               private db: AngularFirestore, private filterService: FilterService,
               private permissionService: PermissionService) {
    /*    this.addProduct(this.products_preset[0]);
        this.addProduct(this.products_preset[1]);*/

    /* Load all data or only user specific data depending on user role */
    if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
      this.productlistsRef = db.collection<any>('environment/' + environment.name + '/productlists',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id));
    } else {
      this.productlistsRef = db.collection<any>('environment/' + environment.name + '/productlists',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
          .where('contact_ids', 'array-contains', environment.currentAuthUser.contact_id));
    }


    this.productlistsObserve$ = this.productlistsRef.snapshotChanges().pipe(
      tap(t => {
        this.productlists = [];
        this.pricelists = [];
        this.products = [];
      }),
      map(actions => actions.map(productlist => {

        let doc_data = productlist.payload.doc.data();
        doc_data['__document__key'] = productlist.payload.doc.id;

        if (productlist['type'] === 'added' || 'modified') {
          this.productlists.push(doc_data);

          const pricelists = [];
          const products = [];

          if (doc_data.pricelists) {
            doc_data.pricelists.forEach(function (element, index, array) {
              array[index].parent_doc = productlist.payload.doc.id;
              array[index].parent_name = doc_data.name;
              array[index].special_id = productlist.payload.doc.id + '-' + index;
              pricelists.push(array[index]);
            });

            this.pricelists = [...this.pricelists, ...pricelists];
          }

          if (doc_data.products) {
            doc_data.products.forEach(function (element, index, array) {
              array[index].productlist_name = doc_data.name;
              array[index].productlist_id = doc_data.__document__key;
              products.push(array[index]);
            });

            this.products = this.products.concat(products);
          }
        }

        return productlist;
      }))
    );

    this.productlistsObserve$.subscribe(() => {
      this.productsListener.next([Date.now()]);
    });

  }

/*  addProduct(product: Product) {
    this.products.push(product);
    this.productsChanged.emit(this.products.slice());
    this.dataManagementService.saveData('products', this.products);
    console.log(this.products);
  }

  updateProduct(id: number, product: Product) {
    this.products[id] = product;
    this.productsChanged.emit(this.products.slice());
    this.dataManagementService.saveData('products', this.products);
    console.log(this.products);
  }*/

  getProducts(contact_id: string | null, country: string | null) {
    let products = [];


    this.productlists.forEach((productlist, index) => {
      if (contact_id && productlist.contact_ids && productlist.contact_ids.includes(contact_id)) {
        if ((country && productlist.only_countries && productlist.only_countries.includes(country)) ||
          (country && !productlist.only_countries) ||
          (country === null || productlist.only_countries === null)) {
          products = products.concat(productlist.products);
        }
      }

      if (!contact_id) {
        products = products.concat(productlist.products);
      }
    });

    products.forEach((product, p_key) => {
      delete products[p_key].productlist_id;
      delete products[p_key].productlist_name;
    });

    return products;
  }

  removeProductlist(productlist_id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/productlists').doc(productlist_id).delete();
  }

  addProductlist(productlist: any) {
    const productlist_c = {...productlist};
    delete productlist_c.__document__key;

    productlist_c.products.forEach((product, pr_key) => {
      productlist_c.products[pr_key].uuid = uuidv4();
    });

    return this.productlistsRef.add(productlist_c);
  }

  updateProductlist(productlist_id: string, productlist: any) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    return productlistRef.get().pipe(map(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {

        if (productlist.products) {
          productlist.products.forEach((product, pr_key) => {
            if (product.uuid === undefined || product.uuid === null) {
              productlist.products[pr_key].uuid = uuidv4();
            }
          });
        }

        const productlist_t = {... productlist};
        delete productlist_t.__document__key;

        return productlistRef.update(productlist_t);
      }
    }));
  }

  addContactToProductlist(productlist_id: string, contact_id: string) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    const getDoc = productlistRef.get().pipe(tap(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        const productlist = doc.data();
        if (!productlist.contact_ids.includes(contact_id)) {
          productlist.contact_ids.push(contact_id);
        }
        return productlistRef.update(productlist);
      }
    }));

    return getDoc.toPromise();
  }

  removeContactToProductlist(productlist_id: string, contact_id: string) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    const getDoc = productlistRef.get().pipe(tap(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        const productlist = doc.data();
        if (productlist.contact_ids.includes(contact_id)) {
          productlist.contact_ids.splice(productlist.contact_ids.indexOf(contact_id), 1);
        }
        console.log(productlist);
        return productlistRef.update(productlist);
      }
    }));

    return getDoc.toPromise();
  }

  addPricelist(productlist_id: string, pricelist: any) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    return productlistRef.get().pipe(map(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc.data());
          let pricelists = doc.data().pricelists;
          if (pricelists) {
            pricelists.push(pricelist);
          } else {
            pricelists = [];
            pricelists.push(pricelist);
          }

          return productlistRef.update({
            pricelists: pricelists
          });
        }
      }));

  }

  addContactToPricelist(productlist_id: string, pricelist_id: string, contact_id: string) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    const getDoc = productlistRef.get().pipe(tap(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        const productlist = doc.data();

        const pricelist_key = this.filterService.findArrayMultiKey(productlist.pricelists, 'id',
          pricelist_id);

        if (!productlist.pricelists[pricelist_key].contact_ids.includes(contact_id)) {
          productlist.pricelists[pricelist_key].contact_ids.push(contact_id);
        }

        const updated_pricelist = { pricelists: null };
        updated_pricelist.pricelists = productlist.pricelists;

        return productlistRef.update(updated_pricelist);
      }
    }));

    return getDoc.toPromise();
  }

  removeContactToPricelist(productlist_id: string, pricelist_id: string, contact_id: string) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    const getDoc = productlistRef.get().pipe(tap(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        const productlist = doc.data();

        const pricelist_key = this.filterService.findArrayMultiKey(productlist.pricelists, 'id',
          pricelist_id);

        if (productlist.pricelists[pricelist_key].contact_ids.includes(contact_id)) {
          productlist.pricelists[pricelist_key].contact_ids.splice(productlist.pricelists[pricelist_key].contact_ids.indexOf(contact_id), 1);
        }

        const updated_pricelist = { pricelists: null };
        updated_pricelist.pricelists = productlist.pricelists;

        return productlistRef.update(updated_pricelist);
      }
    }));

    return getDoc.toPromise();
  }

  removePricelist(productlist_id: string, pricelist_key: number) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    const getDoc = productlistRef.get()
      .subscribe(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc.data());
          const pricelists = doc.data().pricelists;
          pricelists.splice(pricelist_key, 1);
          return productlistRef.update({
            pricelists: pricelists
          });
        }
      });
  }

  updatePricelist(productlist_id: string, pricelist_key: number, pricelist: any) {
    const productlistRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists')
      .doc(productlist_id);

    return productlistRef.get().pipe(map(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        const pricelists = doc.data().pricelists;
        pricelists[pricelist_key] = pricelist;
        console.log(pricelist);
        return productlistRef.update({
          pricelists: pricelists
        });
      }
    }));
  }

  getProductlists(id: string) {
    const key = this.filterService.findArrayMultiKey(this.productlists, '__document__key', id);
    return this.productlists[key];
  }

  getProductsForShipmentInsertPage(contact_id) {
    const productlistsRef = this.db
      .collection<any>('environment/' + environment.name + '/productlists',
      ref => ref.where("contact_ids", "array-contains", contact_id));

    const productlistsObserve$ = productlistsRef.snapshotChanges().pipe(
      tap(t => {
        this.productlists = [];
        this.pricelists = [];
      }),
      map(actions => actions.map(productlist => {

        let doc_data = productlist.payload.doc.data();
        doc_data['__document__key'] = productlist.payload.doc.id;

        if (productlist['type'] === 'added') {
          this.productlists.push(doc_data);

          const pricelists = [];

          if (doc_data.pricelists) {
            doc_data.pricelists.forEach(function (element, index, array) {
              array[index].parent_doc = productlist.payload.doc.id;
              array[index].parent_name = doc_data.name;
              pricelists.push(array[index]);
            });

            this.pricelists = [...pricelists];
          }
        }
      }))
    );
  }
}
