import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class FilterService {

  tmp_value;

  __findIndex(element) {
    return element = this.tmp_value;
  }

  /* SOURCE: https://codereview.stackexchange.com/questions/197345/js-code-that-search-through-every-property-in-a-nested-object */

  findFromList(list, keyword) {
    return (keyword) ? list.filter((el) =>
      this.search(el, keyword.toString().toLowerCase())) : list;
  }

  search(el, keyword) {
    const type = Array.isArray(el) ? 'array' : typeof el;
    const searchFunc = this.getFuncByType(type);

    return searchFunc(el, keyword);
  }

  getFuncByType(type) {

    const match = {
      'string': this.searchInText.bind(this),
      'number': this.searchInText.bind(this),
      'boolean': this.searchInText.bind(this),
      'array': this.searchInArray.bind(this),
      'object': this.searchInObject.bind(this),
    };

    if (typeof match[type] !== 'undefined') {
      return match[type];
    } else {
      throw new Error(`Unknown element type "${type}"`);
    }
  }

  searchInText(text, keyword) {
    return (text.toString().toLowerCase().indexOf(keyword) !== -1);
  }

  searchInObject(obj, keyword) {
    return (obj === null) ? false : this.searchInArray(Object.values(obj), keyword);
  }

  searchInArray(arr, keyword) {
    return arr.find((el) => this.search(el, keyword)) !== undefined;
  }

  /* find key by value in multi-dimensional array */
  findArrayMultiKey(arr: any[], key_name: string, value: string | number | boolean) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key_name] === value) {
        return i;
      }
    }
    return -1;
  }

  /* find key by value in another array in multi-dimensional array */
  findArrayMultiKeyIncludes(arr: any[], key_name: string, value_in_array: string | number | boolean) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key_name].includes(value_in_array)) {
        return i;
      }
    }
    return -1;
  }

  /* find numeric index in flat array */
  findIndex(arr: any[], value: any) {
    this.tmp_value = value;
    const result = arr.findIndex(this.__findIndex.bind(this));
    this.tmp_value = null;
    return result;
  }

  /* filter by specific key & value */
  filterArrayByKeyValue(array, key, value: string | number | boolean | null) {
    const newArr = []; // creating a new array to store the values
    array.forEach((item, index) => {
      if (item[key] === value) { // check if the phase name coresponds with the argument
        newArr.push(item); // push the coressponding value to the new array
      }
    }); // looping through the original array
    return newArr; // return the new array with filtered values
  }

  /* check if item is in array twice (or more)
  * https://stackoverflow.com/questions/40305789/check-if-element-is-in-array-twice-time*/
  countInArray(array, what) {
    let count = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }

  extractFromString(string, prefix, suffix) {
    let s = string;
    let i = s.indexOf(prefix);
    if (i >= 0) {
      s = s.substring(i + prefix.length);
    } else {
      return '';
    }
    if (suffix) {
      i = s.indexOf(suffix);
      if (i >= 0) {
        s = s.substring(0, i);
      } else {
        return '';
      }
    }
    return s;
  }

  ObjectLength(object) {
    return Object.keys(object).length;
  }

  /* remove duplicates from array of objects by property
  * source: https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/ */
  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  sortArrayOfObjects(array, key_name, sort = 'asc') {
    if (array && array.length > 1) {
      if (sort === 'desc' || sort === 'DESC') {
        return array.sort((a, b) => a[key_name].toString().localeCompare(b[key_name])).reverse();
      } else {
        return array.sort((a, b) => a[key_name].toString().localeCompare(b[key_name]));
      }
    } else {
      return array;
    }
  }

  getCombinations(options, optionIndex, results, current) {
    const allKeys = Object.keys(options);
    const optionKey = allKeys[optionIndex];

    const vals = options[optionKey];

    if (vals && vals.length > 0) {
      for (let i = 0; i < vals.length; i++) {
        current[optionKey] = vals[i];

        if (optionIndex + 1 < allKeys.length) {
          this.getCombinations(options, optionIndex + 1, results, current);
        } else {
          // The easiest way to clone an object.
          const res = JSON.parse(JSON.stringify(current));
          results.push(res);
        }
      }
    } else {
      if (optionIndex + 1 < allKeys.length) {
        this.getCombinations(options, optionIndex + 1, results, current);
      } else {
        // The easiest way to clone an object.
        const res = JSON.parse(JSON.stringify(current));
        results.push(res);
      }
    }

    return results;
  }

  splitArray(inputArray, perChunk) {

    return inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

}
