// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  version: require('../../package.json').version,
  sentry_version: 'SENTRY_VERSION',
  name: 'DEV',
  production: false,
  apiURL: 'https://testing-dot-sendnow-app1.appspot.com/api',
  functionsURL: 'https://europe-west1-sendnow-fdb.cloudfunctions.net',
  licenseholder_id: null,
  currentAuthUser: null,
  currentContact: null,
  currentLicenseholder: null,
  myVisitingAddress: null,
  maxShipmentsDrawing: 1250,
  oauth_client_id: 'sendnow_angular',
  oauth_client_secret: '4j38po25Tvw7EoD',
  oauth_scope: 'all',
  firebase: {
    apiKey: "AIzaSyAhBkE3E2jpAA9CmrMb7WRpbxViYREq_uw",
    authDomain: "sendnow-fdb.firebaseapp.com",
    databaseURL: "https://sendnow-fdb.firebaseio.com",
    projectId: "sendnow-fdb",
    storageBucket: "sendnow-fdb.appspot.com",
    messagingSenderId: "573740441505",
    appId: "1:1094957881302:android:c565842fe45e6474"
  }
};
