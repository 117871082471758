import {Injectable} from '@angular/core';
import {ShipmentService} from './data-sync/shipment.service';
import {PdfService} from './pdf.service';
import {LicenseholderService} from './data-sync/licenseholder.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ContactsService} from './data-sync/contacts.service';
import {PrintService} from './print.service';
import {finalize} from 'rxjs/operators';
import {Document} from '../models/shipment.model';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable, Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {AddressService} from './data-sync/address.service';
import {DefinedInfo} from '../models/definedinfo';
import {FilterService} from './filter.service';

@Injectable()
export class PackingSlipService {
  downloadURL: Observable<string | null>;
  public pdfUrlSubject = new Subject<any>();

  constructor(private shipmentService: ShipmentService, private pdfService: PdfService,
              private licenseholderService: LicenseholderService,
              private translate: TranslateService,
              private contactsService: ContactsService,
              private printService: PrintService,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private addressService: AddressService,
              private definedInfo: DefinedInfo,
              private filterService: FilterService) {

  }

  generatePackingSlip(shipment_id, type = 'both', should_print_pickup = false, return_promise = false) {
    /* get new shipment */
    return this.db.collection<any>('environment/' + environment.name + '/shipments')
      .doc(shipment_id)
      .ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          const shipment_processed = doc.data();
          shipment_processed.__document__key = doc.id;

          const licenseholder = this.licenseholderService.getLicenseholder(environment.licenseholder_id);
          const myself = this.contactsService.getContacts(shipment_processed.contact_id);
          /*const myself_address = this.addressService.getAddress(myself.visiting_address_id);*/
          let myself_legal_name = myself.legal_name;

          if (myself.system_logic.includes('dropshipping')) {
            myself_legal_name = ' ';
          }

          return this.db.collection<any>('environment/' + environment.name + '/addresses')
            .doc(myself.visiting_address_id).get().toPromise().then((addr_doc) => {
            let myself_address = addr_doc.data();


            let rembours_total = 0;

            const productsForPackingSlip = [];
            shipment_processed.products.forEach((product, p_key) => {
              rembours_total = rembours_total + product.rembours;

              productsForPackingSlip.push({
                name: product.name,
                product_count: product.amount,
                product_weight: product.weight
              });
            });


            let fromName = null;
            let toName = null;
            let typeAddress = this.translate.instant('mainapp.packing_slip.business');
            const addressTypesArr = ['address_from', 'address_to'];

            addressTypesArr.forEach((addrType) => {
              let name;
              if (shipment_processed[addrType].company_name && shipment_processed[addrType].person_name) {
                name = shipment_processed[addrType].person_name + ' ' +
                  this.translate.instant('mainapp.packing_slip.from') + ' ' +
                  shipment_processed[addrType].company_name;
              }
              if (!shipment_processed[addrType].company_name && shipment_processed[addrType].person_name) {
                name = shipment_processed[addrType].person_name;
                if (addrType === 'address_to') {
                  typeAddress = this.translate.instant('mainapp.packing_slip.private');
                }
              }
              if (shipment_processed[addrType].company_name && !shipment_processed[addrType].person_name) {
                name = shipment_processed[addrType].company_name;
              }

              if (addrType === 'address_from') {
                if (!name) {
                  name = ' ';
                }
                fromName = name;
              }
              if (addrType === 'address_to') {
                if (!name) {
                  name = ' ';
                }
                toName = name;
              }
            });

            let shipmentMoment = '';
            this.definedInfo.shipment_types.forEach((shipment_type) => {
              if (shipment_processed.labels && shipment_processed.labels.length > 0) {
                if (this.filterService.findArrayMultiKey(shipment_processed.labels, 'tag', shipment_type.value) !== -1) {
                  shipmentMoment = shipment_type.name;
                }
              } else {
                shipmentMoment = 'Day';
              }
            });

            if (!shipmentMoment || shipmentMoment === '') {
              shipmentMoment = 'Day';
            }


            let notes = shipment_processed.notes;

            if (notes === null) {
              notes = ' ';
            }

            if (!myself_address) {
              myself_address = {
                address: 'GEA',
                postalcode: 'GEA',
                city: 'GEA',
                country: 'NL / GEA',
                phone_number: 'GEA'
              };
            }



            const vars = {
              shipment_code: shipment_processed.shipment_code,
              barcode: 'SN' + licenseholder.__document__key + '-' + shipment_processed.shipment_code,
              generation_date: moment().format('DD-MM-YYYY HH:mm'),
              from_companyname: fromName,
              from_address: shipment_processed.address_from.address,
              from_postalcodecity: shipment_processed.address_from.postalcode.toString().replace(/ /g, '')
                  .toUpperCase() + ' ' +
                shipment_processed.address_from.city,
              from_country: shipment_processed.address_from.country,
              from_phone_number: shipment_processed.address_from.phone_number,
              to_companyname: toName,
              to_address: shipment_processed.address_to.address,
              to_postalcodecity: shipment_processed.address_to.postalcode + ' ' +
                shipment_processed.address_to.city,
              to_country: shipment_processed.address_to.country,
              to_phone_number: shipment_processed.address_to.phone_number,
              default_companyname: myself_legal_name,
              default_address: myself_address.address,
              default_postalcodecity: myself_address.postalcode.toString().replace(/ /g, '')
                  .toUpperCase() + ' ' +
                myself_address.city,
              default_country: myself_address.country,
              default_phone_number: myself_address.phone_number,
              comments: notes,
              type_shipment: shipmentMoment,
              transportcompany_name: licenseholder.name,
              transportcompany_pakbon_address: licenseholder.depots[0].address,
              transportcompany_pakbon_postal: licenseholder.depots[0].postalcode,
              transportcompany_pakbon_city: licenseholder.depots[0].city,
              transportcompany_phone: licenseholder.phone_number,
              type_address: typeAddress,
              transportcompany_city: licenseholder.information.invoice_city,
              today: moment().format('DD-MM-YYYY'),
              remboursamount: '€ ' + rembours_total.toFixed(2),
              products: productsForPackingSlip
            };

            //console.log(vars);

            const vars_pickup = {...vars};
            const vars_send = {...vars};
            if (myself.system_logic.includes('dropshipping')) {
              vars_send.default_phone_number = ' ';
            }



            if (licenseholder.cloud_print_enabled === true) {
              if (vars.default_postalcodecity !== vars.from_postalcodecity) {
                should_print_pickup = true;
              }
            }

            if (type === 'pickup' && return_promise === false) {
              this.pdfService.generatePackingSlip('pickup', vars_pickup, should_print_pickup)
                .then((object) => {
                  this.__generated(object, shipment_processed);
                });
            } else if (type === 'pickup' && return_promise === true) {
              return this.pdfService.generatePackingSlip('pickup', vars_pickup, should_print_pickup);
            }

            if (type === 'send' && return_promise === false) {
              this.pdfService.generatePackingSlip('send', vars_send, licenseholder.cloud_print_enabled)
                .then((object) => {
                  this.__generated(object, shipment_processed);
                });
            } else if (type === 'send' && return_promise === true) {
              return this.pdfService.generatePackingSlip('send', vars_send, licenseholder.cloud_print_enabled);
            }

            if (type === 'both' && return_promise === false) {
              this.pdfService.generatePackingSlip('pickup', vars_pickup, should_print_pickup)
                .then((object) => {
                  this.__generated(object, shipment_processed);
                });
              this.pdfService.generatePackingSlip('send', vars_send, licenseholder.cloud_print_enabled)
                .then((object) => {
                  this.__generated(object, shipment_processed);
                });
            } else if (type === 'both'  && return_promise === true) {
              return this.pdfService.generatePackingSlip('send', vars_send, licenseholder.cloud_print_enabled);
            }


          });

        } else {
          console.log('shipment doesnt exist...');
        }
      });
  }

  __generated(object, shipment_processed) {
    const file = object.blob;
    const type = object.type;

    const filePath = environment.name + '/packing_slips/' + environment.licenseholder_id + '/' + shipment_processed.shipment_code
      + '_' + type + '_' +
      Math.floor(Date.now() / 1000) + '.pdf';
    const ref = this.storage.ref(filePath);
    const task = ref.put(file).then(() => {

      ref.getDownloadURL().toPromise().then(ref_u => {
        console.log('REF', ref_u);
        const url = this.downloadURL = ref_u;

        this.pdfUrlSubject.next(url);
        const shipment = shipment_processed;
        let documents = [];
        if (shipment && shipment.documents) {
          documents = shipment.documents;
        }
        if (type === 'send') {
          documents.push(new Document({type_id: 2, url}));
        }
        if (type === 'pickup') {
          documents.push(new Document({type_id: 1, url}));
        }
        const shipment_with_doc = {documents: JSON.parse(JSON.stringify(documents))};
        this.shipmentService.updateShipment(shipment_processed.__document__key, shipment_with_doc).subscribe((u) => u.then((d) => {
          console.log('inserted document');
        }));

        if (object.print === true) {
          this.printService.printPDF(url, type + ' shipment ' + shipment_processed.shipment_code)
            .then(() => {
              console.log('sent to print queue');
            });
        }

      });
    });

  }
}
