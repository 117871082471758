import {Address} from '../../models/address.model';
import {EventEmitter, Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {DataManagementService} from './data_management.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Shipment} from '../../models/shipment.model';
import {ApiService} from './api.service';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';
import {PermissionService} from './permission.service';
import {CheckData} from '@bit/calvinnl.sendnow.check_data/dist/shared_components/check_data';

@Injectable()
export class ShipmentService {
  public shipments: Shipment[] = [];

  public shipmentRef: AngularFirestoreCollection<any>;
  public shipmentObserve$: Observable<any>;

  public shipmentsListener = new BehaviorSubject([]);


  constructor(private dataManagementService: DataManagementService,
              private apiService: ApiService,
              private db: AngularFirestore,
              private filterService: FilterService,
              private permissionService: PermissionService,
              private checkData: CheckData) {
    /* , ref => ref.where('user_id', '==', '1') */
    if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
      this.shipmentRef = db.collection<Shipment>('environment/' + environment.name + '/shipments',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id).orderBy('shipment_code', 'desc').limit(+environment.maxShipmentsDrawing));
    } else {
      this.shipmentRef = db.collection<any>('environment/' + environment.name + '/shipments',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
          .orderBy('shipment_code', 'desc')
          .where('contact_id', '==', environment.currentAuthUser.contact_id).limit(+environment.maxShipmentsDrawing));
    }

    /*
    this.shipmentObserve$ = this.shipmentRef.snapshotChanges().pipe(
      tap(t => {
        this.shipments = [];
      }),
      map(actions => actions.map(shipment => {
        let doc_data = shipment.payload.doc.data();
        doc_data['__document__key'] = shipment.payload.doc.id;

        if (shipment['type'] === 'added' || shipment['type'] === 'modified') {
          this.shipments.push(doc_data);
        }

        /!*        return shipment;*!/
      }))
    );
    this.shipmentObserve$.subscribe(() => {
      this.shipmentsListener.next([Date.now()]);
    });*/


    /* this.addShipment(this.shipments_preset[0]);*/

  }


  addShipment(shipment: Shipment) {
    const shipment_c = {...shipment};
    delete shipment_c.__document__key;
    return this.shipmentRef.add(shipment_c);
  }

  addShipmentBatch(shipments: Shipment[]) {
    const batch = this.db.firestore.batch();
    const shipments_c: any = [...shipments];
    const l = shipments_c.length;
    for (let x = 0; x < l; x++) {
      delete shipments_c[x].__document__key;
      const newShipmentRef = this.db.firestore.collection('environment/' + environment.name + '/shipments')
        .doc();
      batch.set(newShipmentRef, shipments_c[x]);
    }

    return batch.commit();
  }


  updateShipment(id: string, shipment: any) {
    const shipmentRef = this.db
      .collection<any>('environment/' + environment.name + '/shipments')
      .doc(id);

    const getDoc = shipmentRef.get();

    const shipment_c = {...shipment};
    delete shipment_c.__document__key;

    return getDoc.pipe(map(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(shipment_c);

        return shipmentRef.update(shipment_c);
      }
    }));
  }

  updateShipmentBatch(data: any) {
    console.log(data);
    const batch = this.db.firestore.batch();
    const shipments_c: any = JSON.parse(JSON.stringify(data));
    const l = shipments_c.length;
    for (let x = 0; x < l; x++) {
      delete shipments_c[x].id;

      if (data[x].id) {
        const newShipmentRef = this.db.firestore.collection('environment/' + environment.name + '/shipments')
          .doc(data[x].id);
        batch.update(newShipmentRef, shipments_c[x]);
      }
    }

    return batch.commit();
  }

  removeShipment(id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/shipments').doc(id).delete();
  }

  checkShipment(shipment) {
    return this.checkData.checkShipment(shipment);
  }


  getShipments(id: string): Observable<any> {
/*    const key = this.filterService.findArrayMultiKey(this.shipments, '__document__key', id);
    return this.shipments[key];*/

    return this.db.collection<any>('environment/' + environment.name + '/shipments')
      .doc(id)
      .get()
      .pipe(map((actions => {
        return {__document__key: id, ...actions.data()};
      })));
  }


  findArrayMultiKey(arr: any[], key_name: string, value: string | number) {
    for (let i = 0; i <= arr.length; i++) {
      if (arr[i][key_name] === value) {
        return i;
      }
    }
    return -1;
  }

  /* add & update label on shipment */
  addLabel(shipment_id, label) {
    const shipmentRef = this.db
      .collection<any>('environment/' + environment.name + '/shipments')
      .doc(shipment_id);

    const getDoc = shipmentRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        const labels = doc.data().labels;

        /* find label*/
        const labelExists = this.filterService.findArrayMultiKey(labels, 'tag', label.tag);
        if (labelExists !== -1) {
          labels[labelExists] = Object.assign({}, label);
        } else {
          labels.push(Object.assign({}, label));
        }

        console.log(labels);

        return shipmentRef.update({
          labels: labels
        });
      }
    });

    return getDoc;
  }

  buildQuery(lastItem, filters, limit) {
    return this.db.collection<Shipment>
    ('environment/' + environment.name + '/shipments',
      ref => {
        let query: firebase.firestore.Query = ref;


        if (!filters.date_from || typeof filters.date_to !== 'number') {
          query = query.where('_addedon', '>', 0);
        } else {
          query = query.where('_addedon', '>', filters.date_from);
        }
        if (!filters.date_to || typeof filters.date_to !== 'number') {
          query = query.where('_addedon', '<', 9999999999);
        } else {
          query = query.where('_addedon', '<', filters.date_to);
        }

        if (filters.shipment_code && !isNaN(filters.shipment_code) && filters.shipment_code.length === 8) {
          query = query.where('shipment_code', '==', +filters.shipment_code);
        }

        query = query.where('licenseholder_id', '==', environment.licenseholder_id);

        if (filters && filters.status && filters.status.length > 0) {
          query = query.where('status', 'in', filters.status);
        }

        if (!this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
          query = query.where('contact_id', '==', environment.currentAuthUser.contact_id);
        }
        if (filters && filters.contactId && filters.contactId.length > 0) {
          query = query.where('contact_id', '==', filters.contactId);
        }

        query = query.orderBy('_addedon', 'desc');

        if (lastItem) {
          query = query.startAfter(lastItem._addedon);
        }

        query = query.limit(limit);

        return query;
      }).snapshotChanges().pipe(map(actions => {
      return this.processDataAfterQuery(actions);
    }));
  }


  processDataAfterQuery(actions) {
    return actions.map(action => {
      const data = action.payload.doc.data();
      const id = action.payload.doc.id;
      return {__document__key: id, ...data};
    });
  }

}
