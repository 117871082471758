export class Permission {
  public roles = [
    {
      name: 'FULL_ADMIN',
      permissions: [
        'SHIPMENTS',
        'ADMIN_USERS',
        'INVOICES',
        'ADMIN_INVOICES',
        'SEND_SHIPMENT_AS_OTHER_CONTACT',
        'USER_MNGT',
        'ADD_ADMINS'
      ]
    },
    {
      name: 'FULL_USER',
      permissions: [
        'SHIPMENTS',
        'INVOICES',
        'USER_MNGT'
      ]
    }
  ];
}
