import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LicenseholderService} from './data-sync/licenseholder.service';
import {Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable()
export class PdfService {
  httpOptions;
  public pdfSubject = new Subject<any>();

  constructor (private http: HttpClient, private licenseholderService: LicenseholderService,
               private db: AngularFirestore) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  async generatePackingSlip(type: string, vars: any, print: boolean) {
    let template;
    if (type === 'pickup') {
      template = this.licenseholderService.getLicenseholder(environment.licenseholder_id).templates.packingSlipPickupTemplateId;
    }
    if (type === 'send') {
      template = this.licenseholderService.getLicenseholder(environment.licenseholder_id).templates.packingSlipSendTemplateId;
    }

    const responseFirst: any = await this.http.post<any>('https://cors-proxy-263511.appspot.com/http://g.sendnow.eu/templategetter.php',
      {
        template: template,
        vars: vars
      }, this.httpOptions).toPromise();

    //console.log(responseFirst);

    const httpOptionsPDF = {... this.httpOptions};
    httpOptionsPDF.responseType = 'arraybuffer';

    const responseSecond: any = await this.http.post<any>('https://cors-proxy-263511.appspot.com/http://g.sendnow.eu/',
      {
        html: responseFirst.html,
        page_size: 'A4',
        page_orientation: 'landscape'
      }, httpOptionsPDF).toPromise();

    const blob = new Blob([responseSecond], { type: 'application/pdf'});
    const object = { blob: blob, type: type, print: print};
    if (type === 'send') {
      this.pdfSubject.next(object);
    }
    return object;

  }

  async generateInvoice(vars: any) {
    let template;
    template = this.licenseholderService.getLicenseholder(environment.licenseholder_id).templates.invoiceTemplateId;

    const responseFirst: any = await this.http.post<any>('https://cors-proxy-263511.appspot.com/http://g.sendnow.eu/templategetter.php',
      {
        template: template,
        vars: vars
      }, this.httpOptions).toPromise();

    //console.log(responseFirst);

    const httpOptionsPDF = {... this.httpOptions};
    httpOptionsPDF.responseType = 'arraybuffer';

    const responseSecond: any = await this.http.post<any>('https://cors-proxy-263511.appspot.com/http://g.sendnow.eu/',
      {
        html: responseFirst.html,
        page_size: 'A4',
        page_orientation: 'portrait'
      }, httpOptionsPDF).toPromise();

    const blob = new Blob([responseSecond], { type: 'application/pdf'});
    const object = { blob: blob};

    return object;
  }

  addToPdfQueue(type, item_id) {
    return this.db.collection<any>('environment/' + environment.name + '/pdf_jobs')
      .add({
        type: type,
        item_id: item_id,
        licenseholder_id: environment.licenseholder_id,
        processed: false
      });
  }

  listenToPdfQueue(type) {
    const listenRef = this.db.collection<any>('environment/' + environment.name + '/pdf_jobs',
      ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
        .where('type', '==', type)
        .where('processed', '==', false).limit(1));
    return listenRef.snapshotChanges().pipe(
      map(actions => actions.map(pdf_job => {
        let doc_data = pdf_job.payload.doc.data();
        doc_data['__document__key'] = pdf_job.payload.doc.id;

        if (pdf_job['type'] === 'added' || pdf_job['type'] === 'modified') {
          return doc_data;
        }
      }))
    );
  }


}
