import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {FilterService} from './filter.service';
@Injectable()
export class MatchRulesService {
  constructor(private db: AngularFirestore, private filterService: FilterService) {

  }

  checkMatchRule(matchRule, data, currentItem = null) {

    if (matchRule.type === 'shipment') {
      return this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
        data[matchRule.column], matchRule.value));
    }

    if (matchRule.type === 'specific_product') {
      if (currentItem === null) {
        if (Array.isArray(matchRule.value)) {
          let inner_matched = false;

          data.products.forEach((product, index) => {
            if (matchRule.value.includes(product.id)) {
              inner_matched = true;
            }
          });

          return this.returnMatchStatus(matchRule.should_match, inner_matched);
        }

        const key = this.filterService.findArrayMultiKey(data.products, 'id', matchRule.value);
        if (key === -1 && matchRule.operator === '!=') {
          return this.returnMatchStatus(matchRule.should_match, true);
        }

        if (key !== -1 && matchRule.operator === '=') {
          return this.returnMatchStatus(matchRule.should_match, true);
        }

        /* but what if matchRule.value is array */
        let productIds;
        if (Array.isArray(matchRule.value)) {
          productIds = matchRule.value;
        } else {
          productIds = matchRule.value.split(',');
        }

        let inner_matched = false;

        data.products.forEach((product, index) => {
          if (productIds.includes(product.id)) {
            inner_matched = true;
          }
        });

        return this.returnMatchStatus(matchRule.should_match, inner_matched);
      } else {
        /* but what if matchRule.value is array */
        let productIds;
        if (Array.isArray(matchRule.value)) {
          productIds = matchRule.value;
        } else {
          productIds = matchRule.value.split(',');
        }

        let inner_matched = false;

        if (productIds.includes(currentItem.uuid)) {
          inner_matched = true;
        }

        return this.returnMatchStatus(matchRule.should_match, inner_matched);
      }
    }

    if (matchRule.type === 'count_total_products') {
      let productcount = 0;
      data.products.forEach((product, index) => {
        productcount = productcount + product.amount;
      });
      return this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
        productcount, matchRule.value));
    }

    if (matchRule.type === 'per_product') {
      let matched = false;
      data.products.forEach((product, index) => {
        const matched_single = this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
          product[matchRule.column], matchRule.value));
        if (matched_single) {
          matched = true;
        }
      });
      return matched;
    }

    if (matchRule.type === 'from_address' || 'to_address' || 'both_addresses') {
      /* add postalcodecountry */
      if (data.address_from.postalcode && data.address_to.postalcode) {
        data.address_from.postalcodecountry = data.address_from.postalcode + '_' + data.address_from.country;
        data.address_to.postalcodecountry = data.address_to.postalcode + '_' + data.address_to.country;
      }

      if (matchRule.type === 'from_address') {
        return this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
          data.address_from[matchRule.column], matchRule.value));
      }
      if (matchRule.type === 'to_address') {
        return this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
          data.address_to[matchRule.column], matchRule.value));
      }
      if (matchRule.type === 'both_addresses') {
        const fromResult = this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
          data.address_from[matchRule.column], matchRule.value));
        const toResult = this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
          data.address_to[matchRule.column], matchRule.value));
        return (fromResult || toResult);
      }
    }

    if (matchRule.type === 'contact') {
      return this.returnMatchStatus(matchRule.should_match, this.checkMatch(matchRule.operator,
        data.contact_id, matchRule.value));
    }


    if (matchRule.type === 'label') {
      const tag_key = this.filterService.findArrayMultiKey(data.labels, 'tag', matchRule.value);
      if (tag_key === -1 && matchRule.operator === '!=') {
        return this.returnMatchStatus(matchRule.should_match, true);
      }

      if (tag_key !== -1 && matchRule.operator === '=') {
        return this.returnMatchStatus(matchRule.should_match, true);
      }
    }
  }

  checkMatch(operator, val_to_check, val_in_rule) {
/*    console.log(operator + '-' + val_to_check + '-' + val_in_rule);*/
    if (operator === '>') {
      return (parseFloat(val_to_check) > parseFloat(val_in_rule));
    } if (operator === '<') {
      return (parseFloat(val_to_check) < parseFloat(val_in_rule));
    } if (operator === '>=') {
      return (parseFloat(val_to_check) >= parseFloat(val_in_rule));
    } if (operator === '<=') {
      return (parseFloat(val_to_check) <= parseFloat(val_in_rule));
    } if (operator === '=') {
      return (val_to_check === val_in_rule);
    } if (operator === '!=') {
      return (val_to_check !== val_in_rule);
    } if (operator === 'CON') {
      return (val_to_check.includes(val_in_rule));
    } if (operator === 'N_EMPTY') {
      return (!val_to_check || val_to_check === '0.00') ? false : true;
    } if (operator === 'IN_RANGE') {
      const range = val_in_rule.split('-');
      return (parseFloat(val_to_check) >= Number(range[0]) && parseFloat(val_to_check) <= Number(range[1]));
    } if (operator === 'IN_ARR') {
      return val_to_check.includes(val_in_rule.split(','));
    } if (operator === 'NOT_IN_ARR') {
      return !val_to_check.includes(val_in_rule.split(','));
    } if (operator === 'IN_ARR_RANGE') {
      let result = false;
      /* separate ranges into arrays */
      const ranges = val_in_rule.split(',');
      /* lets check every range to see if its true */
      ranges.forEach((val, index, arr) => {
        /* range is in format 1000-2000, change to array by - */
        const range_split = val.split('-');
        if (parseFloat(val_to_check) >= Number(range_split[0]) && parseFloat(val_to_check) <= Number(range_split[1])) {
          result = true;
        }
      });
      return result;
    } if (operator === 'IN_ARR_RANGE_PC') {
      let result = false;
      /* separate ranges into arrays */
      const split_postal_and_country = val_in_rule.split('_');
      const ranges = split_postal_and_country[0].split(',');
      const to_check = val_to_check.split('_');
      /* lets check every range to see if its true */
      ranges.forEach((val, index, arr) => {
        /* range is in format 1000-2000, change to array by - */
        const range_split = val.split('-');
        if (parseFloat(to_check[0]) >= Number(range_split[0]) && parseFloat(to_check[0]) <= Number(range_split[1])) {
          if (to_check[1] === split_postal_and_country[1]) {
            result = true;
          }
        }
      });
      return result;
    }
  }

  returnMatchStatus(should_match, match) {
    if (should_match === true) {
      return (match);
    } else {
      return (!match);
    }
  }
}
