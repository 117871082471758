import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';
import {Invoice} from '../../models/invoice.model';
import {PermissionService} from './permission.service';
import {Shipment} from '../../models/shipment.model';

@Injectable()
export class InvoiceService {

  public invoices: Invoice[] = [];
  public invoicesRef: AngularFirestoreCollection<any>;
  public invoicesObserve$: Observable<any>;

  public invoicesListener = new BehaviorSubject([]);

  constructor(private db: AngularFirestore, private filterService: FilterService,
              public permissionService: PermissionService) {
    if (this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
      this.invoicesRef = db.collection<any>('environment/' + environment.name + '/invoices',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id).limit(500).orderBy('invoice_nr', 'desc'));
    } else {
      this.invoicesRef = db.collection<any>('environment/' + environment.name + '/invoices',
        ref => ref.where('licenseholder_id', '==', environment.licenseholder_id)
          .where('contact_id', '==', environment.currentAuthUser.contact_id).limit(500).orderBy('invoice_nr', 'desc'));
    }

/*    this.invoicesObserve$ = this.invoicesRef.snapshotChanges().pipe(
      tap(t => {
        this.invoices = [];
      }),
      map(actions => actions.map(invoice => {
        let doc_data = invoice.payload.doc.data();
        doc_data['__document__key'] = invoice.payload.doc.id;

        this.invoices.push(doc_data);
      }))
    );*/
/*
    this.invoicesObserve$.subscribe(() => {
      this.invoicesListener.next([Date.now()]);
    });*/
  }

  addInvoice(invoice: Invoice) {
    const invoice_c = {...invoice};
    delete invoice_c.__document__key;

    return this.invoicesRef.add(invoice_c);
  }

  updateInvoice(invoice_id: string, invoice: any) {
    const invoice_c = {...invoice};
    delete invoice_c.__document__key;

    const invoicesRef = this.db
      .collection<any>('environment/' + environment.name + '/invoices')
      .doc(invoice_id);

    const getDoc = invoicesRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log(invoice_c);

        return invoicesRef.update(invoice_c);
      }
    });

    return getDoc;
  }

  getInvoice(invoice_id: string | number, key?: string): Observable<object> {
    if (typeof invoice_id === 'string') {
      return this.db
        .collection<any>('environment/' + environment.name + '/invoices')
        .doc(invoice_id)
        .get()
        .pipe(map(doc => {
          return {__document__key: doc.id, ... doc.data()};
        }));
    }
    if (typeof invoice_id === 'number') {
      const observable = from(this.db
        .collection<any>('environment/' + environment.name + '/invoices')
        .ref
        .where(key, '==', invoice_id)
        .get());

      return observable.pipe(map(snapshot => {
        return {__document__key: snapshot.docs[0].id, ...snapshot.docs[0].data()};
      }));
    }

  }

  buildQuery(lastItem, filters, limit) {
    return this.db.collection<Shipment>
    ('environment/' + environment.name + '/invoices',
      ref => {
        let query: firebase.firestore.Query = ref;


        if (!filters.date_from || typeof filters.date_to !== 'number') {
          query = query.where('_addedon', '>', 0);
        } else {
          query = query.where('_addedon', '>', filters.date_from);
        }
        if (!filters.date_to || typeof filters.date_to !== 'number') {
          query = query.where('_addedon', '<', 9999999999);
        } else {
          query = query.where('_addedon', '<', filters.date_to);
        }

        if (filters.invoice_nr) {
          query = query.where('invoice_nr', '==', +filters.invoice_nr);
        }

        query = query.where('licenseholder_id', '==', environment.licenseholder_id);

        if (filters && filters.status && filters.status.length > 0) {
          query = query.where('status', 'in', filters.status);
        }

        if (!this.permissionService.checkRolePermissions(environment.currentAuthUser.permissions[0], ['ADMIN_USERS'])) {
          query = query.where('contact_id', '==', environment.currentAuthUser.contact_id);
        }
        if (filters && filters.contactId && filters.contactId.length > 0) {
          query = query.where('contact_id', '==', filters.contactId);
        }

        query = query.orderBy('_addedon', 'desc');

        if (lastItem) {
          query = query.startAfter(lastItem._addedon);
        }

        query = query.limit(limit);

        return query;
      }).snapshotChanges().pipe(map(actions => {
      return this.processDataAfterQuery(actions);
    }));
  }


  processDataAfterQuery(actions) {
    return actions.map(action => {
      const data = action.payload.doc.data();
      const id = action.payload.doc.id;
      return {__document__key: id, ...data};
    });
  }

}
