import {Address} from './address.model';
import {Product} from './productsn.model';
import {FilterService} from '../services/filter.service';
import * as moment from 'moment';

export class ShipmentLabel {
  tag: string;
  name: string;
  data: string | null;
}

// 1 pickup
// 2 delivery
// 3 notes
// 4 invoice
// 5 overview (invoice)
export class Document {
  _addedon?: number | null = Math.floor(Date.now() / 1000); // UNIX TIMESTAMP
  type_id: number;
  url: string;

  constructor(d: Document) {
    Object.entries(d).forEach(([key, val]) => {
      this[key] = val;
    });
  }
}


export class Shipment {
  __document__key: string;
  _addedon: number | null = +moment().unix() // UNIX TIMESTAMP
  _updatedon: number | null = null; // UNIX TIMESTAMP
  licenseholder_id: string;
  contact_id: string;
  shipment_code: number;
  products: Product[];
  labels: ShipmentLabel[];
  address_from: Address;
  address_to: Address;
  status: number;
  shipmenttype: string;
  notes: string | null;
  referention: string | null;
  documents: Document[];
  date_retrieved: number | null; // UNIX TIMESTAMP
  date_shipped: number | null; // UNIX TIMESTAMP
  date_invoiced: number | null; // UNIX TIMESTAMP

  constructor(s: Shipment) {
    Object.entries(s).forEach(([key, val]) => {
      this[key] = val;
    });

    const shipmentT = this.processShipmentMagic(this);
    Object.entries(shipmentT).forEach(([key, val]) => {
      this[key] = val;
    });
  }

  processShipmentMagic(shipment: Shipment) {
    const filterService = new FilterService();

    /* shipment AI */
   /* shipment = this.SM_correctLabels(shipment, filterService);*/

    return shipment;
  }

  SM_correctLabels(shipment: Shipment, filterService) {
    /* NO LONGER NECESSARY, DONE BY FIREBASE FUNCTIONS */


    /* cash on delivery label */
    let noCodPresent = null;
    const codData = {
      tag: 'rembours',
      name: 'Rembours',
      data: null
    };
    shipment.products.forEach((product) => {
      if (product.rembours && product.rembours > 0) {
        if (shipment.labels && shipment.labels.length > 0 &&
          filterService.findArrayMultiKey(shipment.labels, 'tag', 'rembours') === -1) {
          shipment.labels.push(codData);
        }
        if (shipment.labels && shipment.labels.length < 1) {
          shipment.labels.push(codData);
        }
        if (!shipment.labels) {
          shipment.labels = [];
          shipment.labels.push(codData);
        }
      } else {
        noCodPresent = true;
      }
    });
    if (noCodPresent === true) {
      if (shipment.labels && shipment.labels.length > 0 &&
        filterService.findArrayMultiKey(shipment.labels, 'tag', 'rembours') !== -1) {
        const CodLabelKey = filterService.findArrayMultiKey(shipment.labels, 'tag', 'rembours');
        delete shipment.labels[CodLabelKey];
      }
    }

    return shipment;
  }
}
