import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LicenseholderService} from './data-sync/licenseholder.service';
import {Subject} from 'rxjs';
import {Shipment} from '../models/shipment.model';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable()
export class PrintService {
  httpOptions;

  alreadyPrintedUrls = [];

  constructor (private http: HttpClient, private licenseholderService: LicenseholderService,
               private db: AngularFirestore) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  printPDF(pdf, job_name) {
    const licenseholder = this.licenseholderService.getLicenseholder(environment.licenseholder_id);

    if (!this.alreadyPrintedUrls.includes(pdf)) {
     /* return this.http.post<any>('https://cors-proxy-263511.appspot.com/http://printing.sendnow.eu/',
        {
          pdf_url: pdf,
          print: 1,
          print_job_name: job_name,
          cloudprint_printer_id: licenseholder.cloud_print_printer_id,
          cloudprint_printer_name: licenseholder.cloud_print_printer_name,
          cloudprint_refresh_token: licenseholder.cloud_print_token
        }, this.httpOptions);

      this.alreadyPrintedUrls.push(pdf);*/

      return this.db.collection<any>('environment/' + environment.name + '/print_jobs')
        .add({
          name: job_name,
          pdf_url: pdf,
          licenseholder_id: environment.licenseholder_id
        });

    }

  }


}
