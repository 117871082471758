import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
  name: 'currencycustom'
})
export class CurrencyCustomPipe implements PipeTransform {
  constructor() {}

  transform(
    value: any, currencyCode?: string,
    display: 'code'|'symbol'|'symbol-narrow'|string|boolean = 'symbol', digitsInfo?: string,
    locale?: string): string|null {

    return '&euro; ' + value;
  }
}
