import {Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../../../environments/environment';
import {TokenHolder} from '../auth/tokenholder';

@Injectable()
export class ApiService {

  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient, private authService: AuthService,
              private tokenHolder: TokenHolder) {
  }

  public async runRequest(request_type, endpoint, data = null) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.tokenHolder.token.access_token
      })
    };

    if (request_type === 'get') {
      const params = null;
      return this.http.get<any>(this.apiUrl + endpoint, {
        params: params,
        headers: httpOptions.headers
      });
    }

    if (request_type === 'post') {
      return this.http.post<any>(this.apiUrl + endpoint, data, httpOptions);
    }

  }

}
