import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';
import {Label} from '../../models/label.model';

@Injectable()
export class LabelService {

  public labels: Label[] = [];
  public labelsRef: AngularFirestoreCollection<any>;
  public labelsObserve$: Observable<any>;

  public labelsListener = new BehaviorSubject([]);


  constructor(private db: AngularFirestore, private filterService: FilterService) {
    this.labelsRef = db.collection<any>('environment/' + environment.name + '/labels');
    this.labelsObserve$ = this.labelsRef.snapshotChanges().pipe(
      tap (t => {
        this.labels = [];
      }),
      map(actions => actions.map(label => {
        let doc_data = label.payload.doc.data();
        doc_data['__document__key'] = label.payload.doc.id;

        if (label['type'] === 'added' || label['type'] === 'modified') {
          this.labels.push(doc_data);
        }

        return label;
      }))
    );
    this.labelsObserve$.subscribe(() => {
      this.labelsListener.next([Date.now()]);
    });
  }


  getLabel(id: string) {
    const key = this.filterService.findArrayMultiKey(this.labels, '__document__key', id);
    return this.labels[key];
  }

  getLabelViaTag(tag: string) {
    const key = this.filterService.findArrayMultiKey(this.labels, 'tag', tag);
    return this.labels[key];
  }


}
