import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DateService {
  constructor(public translate: TranslateService) {}

  getUnixTimestamp() {
    return +moment().unix();
  }

  getUnixTimestampFromTimestamp(timestamp: number) {
    return +moment(timestamp).unix();
  }

  niceDateFormatting(unixTimestamp, format = null) {

    if (unixTimestamp === null || undefined) {
      return '-';
    }
    const date: moment.Moment = moment.unix(unixTimestamp);

    if (format) {
      return date.format(format);
    }


    let diff = date.diff(moment(), 'days', true);

    if (diff > -0.4) {
      return date.format('HH:mm');
    } else {
      return Math.abs(diff).toFixed(0)
        + this.translate.instant('mainapp.date.days_ago');
    }
  }



}
