import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import * as firebase from 'firebase';
import 'firebase/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {NgForageModule, NgForageConfig, NgForage, NgForageCache} from 'ngforage';

import { AvatarModule } from 'ngx-avatar';
import { AgmCoreModule } from '@agm/core';

import {HotkeyModule} from 'angular2-hotkeys';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {AddressService} from './shared/services/data-sync/address.service';
import {LocalDataService} from './shared/services/data-sync/local_data.service';
import {DataManagementService} from './shared/services/data-sync/data_management.service';
import {SocketService} from './shared/services/data-sync/socket.service';
import {AddressResolve} from './views/resolvers/address.resolve';
import {ProductService} from './shared/services/data-sync/product.service';
import {ShipmentService} from './shared/services/data-sync/shipment.service';
import {ApiService} from './shared/services/data-sync/api.service';
import {AuthService} from './shared/services/auth/auth.service';
import {RoutingState} from './shared/services/routingstate.service';
import {UserService} from './shared/services/data-sync/user.service';
import {TokenHolder} from './shared/services/auth/tokenholder';
import {environment} from '../environments/environment';
import {ContactsService} from './shared/services/data-sync/contacts.service';
import {FilterService} from './shared/services/filter.service';
import {AddAddressComponent} from './views/addressmanagement/add-address.component';
import {PriceruleService} from './shared/services/data-sync/pricerule.service';
import {PostalcodeService} from './shared/services/postalcode.service';
import {LabelService} from './shared/services/data-sync/label.service';
import {ShipmentViewerComponent} from './views/shipmenteditor/shipmentviewer.component';
import {LicenseholderService} from './shared/services/data-sync/licenseholder.service';
import {InvoiceService} from './shared/services/data-sync/invoice.service';
import {PdfService} from './shared/services/pdf.service';
import {PriceCalculationService} from './shared/services/price-calculation.service';
import {MatchRulesService} from './shared/services/match-rules.service';
import {PermissionService} from './shared/services/data-sync/permission.service';
import {AuthResolver} from './shared/resolvers/auth.resolver';
import {CountryService} from './shared/services/country.service';
import {PrintService} from './shared/services/print.service';

import * as Sentry from '@sentry/browser';
import {PricelevelService} from './shared/services/data-sync/pricelevel.service';
import {PackingSlipService} from './shared/services/packingslip.service';
import {CsvService} from './shared/services/csv.service';
import {IntegrationService} from './shared/services/integration.service';
import {DefinedInfo} from './shared/models/definedinfo';
import {DateService} from './shared/services/date.service';
import {ContactIdToNamePipe} from './shared/pipes/custom/contact-id-to-name.pipe';
import {CurrencyCustomPipe} from './shared/pipes/custom/currency-custom.pipe';
import {WINDOW_PROVIDERS} from './shared/helpers/window.helper';
import {AngularFirePerformanceModule} from '@angular/fire/performance';
import {AngularFireFunctionsModule, FUNCTIONS_REGION} from '@angular/fire/functions';
import {ScrollService} from './shared/services/scroll.service';
import {CheckData} from '@bit/calvinnl.sendnow.check_data/dist/shared_components/check_data';


if (location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://d8bf521b7c0e4609a93a1ae0383b28fe@sentry.io/1826857',
    environment: environment.name,
    release: environment.version
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (location.hostname !== 'localhost') {
      const eventId = Sentry.captureException(error.originalError || error);
      console.log(JSON.stringify(error));
      if (confirm("We're sorry but an error has occurred. We apologize for the inconvience. " +
        "The page will be refreshed.")) {
        location.reload();
      } else {
      }
      /*Sentry.showReportDialog({eventId});*/
    } else {
      throw(error);
    }
  }
}



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA5-UbT6Pl5NOHj4QkH3avBeelnOzsUYOo',
      language: 'en',
      libraries: ['places'],
      region: 'NL'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false,  onSameUrlNavigation: 'reload' }),
    NgForageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
 //   AngularFirePerformanceModule,
    AngularFireFunctionsModule,
    AvatarModule,
    HotkeyModule.forRoot()
  ],
  declarations: [AppComponent, ContactIdToNamePipe, CurrencyCustomPipe],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    { provide: FUNCTIONS_REGION, useValue: 'europe-west1' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    NgForage, NgForageCache,
    RoutingState,
    LocalDataService,
    DataManagementService,
    AuthService,
    UserService,
    ApiService,
    TokenHolder,
    AddressService,
    AddressResolve,
    AuthResolver,
    ProductService,
    ShipmentService,
    ContactsService,
    PriceruleService,
    FilterService,
    PostalcodeService,
    LabelService,
    LicenseholderService,
    InvoiceService,
    PdfService,
    PriceCalculationService, MatchRulesService,
    PermissionService,
    CountryService,
    PrintService,
    PricelevelService,
    PackingSlipService,
    CsvService,
    DefinedInfo,
    IntegrationService,
    DateService,
    WINDOW_PROVIDERS,
    ScrollService,
    CheckData
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
/*  public constructor(ngfConfig: NgForageConfig) {
    ngfConfig.configure({
      name: 'SENDNOW',
      driver: [ // defaults to indexedDB -> webSQL -> localStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE
      ]
    });
  }*/
}
