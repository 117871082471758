import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../filter.service';

@Injectable()
export class LicenseholderService {

  public licenseholders: any[] = [];
  public licenseholdersRef: AngularFirestoreCollection<any>;
  public licenseholdersObserve$: Observable<any>;

  constructor(private db: AngularFirestore, private filterService: FilterService) {
    this.licenseholdersRef = db.collection<any>('environment/' + environment.name + '/licenseholders');
    this.licenseholdersObserve$ = this.licenseholdersRef.snapshotChanges().pipe(
      tap(t => {
        this.licenseholders = [];
      }),
      map(actions => actions.map(licenseholder => {

        let doc_data = licenseholder.payload.doc.data();
        doc_data['__document__key'] = licenseholder.payload.doc.id;

        if (licenseholder['type'] === 'added') {
          this.licenseholders.push(doc_data);
        }

        if (licenseholder['type'] === 'modified') {
          /*  const key = this.filterService.findArrayMultiKey(this.girls, 'id', doc_data.id);
            this.girls[key] = doc_data;*/
          this.licenseholders.push(doc_data);
        }

        if (licenseholder['type'] === 'removed') {
          const key = this.filterService.findArrayMultiKey(this.licenseholders, 'id', doc_data.id);
          delete (this.licenseholders[key]);
        }

        return licenseholder;
      }))
    );

    /* load data at service init */
    this.licenseholdersObserve$.subscribe();
  }

  getLicenseholder(id: string) {
    const key = this.filterService.findArrayMultiKey(this.licenseholders, '__document__key', id.toString());
    return this.licenseholders[key];
  }

  incrementNr(type: string, licenseholder_id: string, increment_by = 1) {


    const licenseholderRef = this.db
        .collection<any>('environment/' + environment.name + '/licenseholders')
        .doc(licenseholder_id);

      const getDoc = licenseholderRef.get();
      getDoc.subscribe(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          const licenseholder_old = doc.data();
          const licenseholder = <any>{};
          if (type === 'invoice') {
            licenseholder.invoice_nr = licenseholder_old.invoice_nr + increment_by;
          }
          if (type === 'shipment') {
            licenseholder.shipment_code = licenseholder_old.shipment_code + increment_by;
          }

          return licenseholderRef.update(licenseholder);
        }
      });

      return getDoc;
    }

}
