import {Pipe, PipeTransform} from '@angular/core';
import {ContactsService} from '../../services/data-sync/contacts.service';
@Pipe ({
  name : 'contactidtoname'
})
export class ContactIdToNamePipe implements PipeTransform {
  constructor(private contactsService: ContactsService) {}
  transform(val: string): string {
    if (this.contactsService.getContacts(val)) {
      return this.contactsService.getContacts(val).display_name;
    }
    return null;
  }
}
