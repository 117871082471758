import {EventEmitter, Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators/';
import {environment} from '../../../../environments/environment';
import {DataManagementService} from './data_management.service';
import {ApiService} from './api.service';
import {FilterService} from '../filter.service';
import {Pricerule} from '../../models/pricerule.model';
import {Rulematch} from '../../models/rulematch.model';

@Injectable()
export class PriceruleService {

  public pricerules: Pricerule[] = [];
  public pricerulesRef: AngularFirestoreCollection<any>;
  public pricerulesObserve$: Observable<any>;
  public pricerulesListener = new BehaviorSubject([]);


  public rulematches: Rulematch[] = [];
  public rulematchesRef: AngularFirestoreCollection<any>;
  public rulematchesObserve$: Observable<any>;
  public rulematchesListener = new BehaviorSubject([]);

  public pricingchanges: any[] = [];
  public pricingchangesRef: AngularFirestoreCollection<any>;
  public pricingchangesObserve$: Observable<any>;
  public pricingchangesListener = new BehaviorSubject([]);

  activePricerule: string | null = null;

  constructor(private db: AngularFirestore, private filterService: FilterService) {
    this.pricerulesRef = db.collection<any>('environment/' + environment.name + '/pricing_rules',
      ref => ref.where('licenseholder_id', '==', environment.licenseholder_id));
    this.rulematchesRef = db.collection<any>('environment/' + environment.name + '/rule_matches',
      ref => ref.where('licenseholder_id', '==', environment.licenseholder_id));
    this.pricingchangesRef = db.collection<any>('environment/' + environment.name + '/pricing_changes',
      ref => ref.where('licenseholder_id', '==', environment.licenseholder_id));
    this.pricerulesObserve$ = this.pricerulesRef.snapshotChanges().pipe(
      tap (t => {
        this.pricerules = [];
      }),
      map(actions => actions.map(pricerule => {
        let doc_data = pricerule.payload.doc.data();
        doc_data['__document__key'] = pricerule.payload.doc.id;

        if (pricerule['type'] === 'added' || pricerule['type'] === 'modified') {
          this.pricerules.push(doc_data);
        }

        return pricerule;
      }))
    );
    this.rulematchesObserve$ = this.rulematchesRef.snapshotChanges().pipe(
      tap (t => {
        this.rulematches = [];
      }),
      map(actions => actions.map(rulematch => {

        let doc_data = rulematch.payload.doc.data();
        doc_data['__document__key'] = rulematch.payload.doc.id;

        if (rulematch['type'] === 'added' || rulematch['type'] === 'modified') {
          this.rulematches.push(doc_data);
        }

        return rulematch;
      }))
    );
    this.pricingchangesObserve$ = this.pricingchangesRef.snapshotChanges().pipe(
      tap (t => {
        this.pricingchanges = [];
      }),
      map(actions => actions.map(pricingchange => {

        let doc_data = pricingchange.payload.doc.data();
        doc_data['__document__key'] = pricingchange.payload.doc.id;

        if (pricingchange['type'] === 'added' || pricingchange['type'] === 'modified') {
          this.pricingchanges.push(doc_data);
        }

        return pricingchange;
      }))
    );

    this.pricerulesObserve$.subscribe(() => {
      this.pricerulesListener.next([Date.now()]);
    });
    this.rulematchesObserve$.subscribe(() => {
      this.rulematchesListener.next([Date.now()]);
    });
    this.pricingchangesObserve$.subscribe(() => {
      this.pricingchangesListener.next([Date.now()]);
    });
  }

  getPriceRule(pricerule_id: string) {
    const key = this.filterService.findArrayMultiKey(this.pricerules, '__document__key', pricerule_id);
    return this.pricerules[key];
  }

  getRuleMatch(rulematch_id: string) {
    const key = this.filterService.findArrayMultiKey(this.rulematches, '__document__key', rulematch_id);
    return this.rulematches[key];
  }

  getPricechange(pricechange_id: string) {
    const key = this.filterService.findArrayMultiKey(this.pricingchanges, '__document__key', pricechange_id);
    return this.pricingchanges[key];
  }

  addPricerule(pricerule: Pricerule) {
    const pricerule_c = {...pricerule};
    delete pricerule_c.__document__key;
    return this.pricerulesRef.add(pricerule_c);
  }

  addRulematch(rulematch: Rulematch) {
    const rulematch_c = {...rulematch};
    delete rulematch_c.__document__key;
    return this.rulematchesRef.add(rulematch_c);
  }

  addPricechange(pricechange: Rulematch) {
    const pricechange_c = {...pricechange};
    delete pricechange_c.__document__key;
    return this.pricingchangesRef.add(pricechange_c);
  }

  updatePricerule(pricerule_id: string, pricerule: Pricerule) {
    const priceruleRef = this.db
      .collection<any>('environment/' + environment.name + '/pricing_rules')
      .doc(pricerule_id);

    const pricerule_c = {...pricerule};
    delete pricerule_c.__document__key;

    const getDoc = priceruleRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(pricerule_c);

        return priceruleRef.update(pricerule_c);
      }
    });

    return getDoc;
  }

  updateRulematch(rulematch_id: string, rulematch: Rulematch) {
    const ruleMatchRef = this.db
      .collection<any>('environment/' + environment.name + '/rule_matches')
      .doc(rulematch_id);

    const getDoc = ruleMatchRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(rulematch);

        return ruleMatchRef.update(rulematch);
      }
    });

    return getDoc;
  }

  updatePricechange(pricechange_id: string, pricechange: Rulematch) {
    const priceChangeRef = this.db
      .collection<any>('environment/' + environment.name + '/pricing_changes')
      .doc(pricechange_id);

    return priceChangeRef.get().pipe(map(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        console.log(pricechange);

        return priceChangeRef.update(pricechange);
      }
    }));
  }

  addContactToPricerule(pricerule_id: string, contact_id: string) {
    const priceruleRef = this.db
      .collection<any>('environment/' + environment.name + '/pricing_rules')
      .doc(pricerule_id);

    const getDoc = priceruleRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        const pricerule = doc.data();
        if (!pricerule.match_contacts_arr.includes(contact_id)) {
          pricerule.match_contacts_arr.push(contact_id);
        }
        return priceruleRef.update(pricerule);
      }
    });

    return getDoc;
  }

  removeContactToPricerule(pricerule_id: string, contact_id: string) {
    const priceruleRef = this.db
      .collection<any>('environment/' + environment.name + '/pricing_rules')
      .doc(pricerule_id);

    const getDoc = priceruleRef.get();
    getDoc.subscribe(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        console.log('Document data:', doc.data());
        const pricerule = doc.data();
        if (pricerule.match_contacts_arr.includes(contact_id)) {
          pricerule.match_contacts_arr.splice(pricerule.match_contacts_arr.indexOf(contact_id), 1);
        }
        return priceruleRef.update(pricerule);
      }
    });

    return getDoc;
  }



  removePricerule(pricerule_id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/pricing_rules').doc(pricerule_id).delete();
  }

  removeRulematch(rulematch_id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/rule_matches').doc(rulematch_id).delete();
  }

  removePricechange(pricechange_id: string) {
    return this.db.collection<any>('environment/' + environment.name + '/pricing_changes').doc(pricechange_id).delete();
  }

/*  getContacts(id: string) {
    const key = this.filterService.findArrayMultiKey(this.contacts, '__document__key', id);
    return this.contacts[key];
  }*/


}
