
import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FilterService} from './filter.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class PostalcodeService {

/* output of API is:
*  street
* streetNen
* houseNumber
* houseNumberAddition
* postcode
* city
* cityShort
* municipality
* muunicipalityShort
* province
* rdX
* rdY
* latitude
* longitude
* bagNumberDesignationId
* bagAddressableObjectId
* addressType
* purposes (ARR)
* surfaceArea
* houseNumberAdditions
* Key: unzanCezCmrJdlwdd7KbIr92ATUSCNsBSh1eYsZFiBQ
Secret: H5OG8ZFdyzNQPaczeM6BaiEzt6sYRlM2xJZBWchDZEEH9HrEdi
* */

  constructor (private filterService: FilterService, private http: HttpClient) { }

  retrieveAddress(postalcode, housenumber, extension) {
    let url = null;
    let authString = 'unzanCezCmrJdlwdd7KbIr92ATUSCNsBSh1eYsZFiBQ:H5OG8ZFdyzNQPaczeM6BaiEzt6sYRlM2xJZBWchDZEEH9HrEdi';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if (extension) {
      url = 'https://star-servers.nl/cors/www/index.php?url=https://' + authString + '@api.postcode.nl/rest/addresses/postcode/' + postalcode + '/' + housenumber + '/' + extension;
    } else {
      url = 'https://star-servers.nl/cors/www/index.php?url=https://' + authString + '@api.postcode.nl/rest/addresses/postcode/' + postalcode + '/' + housenumber;
    }

    return this.http
      .get(url, httpOptions);

  }

}
