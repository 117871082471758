import {LocalDataService} from './local_data.service';
import {Injectable, EventEmitter} from '@angular/core';

@Injectable()
export class DataManagementService {
 /* public dataChanged = new EventEmitter<[]>();*/

  constructor(private localDataService: LocalDataService) {
   /* this.localDataService.dataChangedLD.subscribe(
      (data) => {*/
        //this.dataChanged.emit(data.substr(0, data.indexOf('-')));
      /*});*/
  }

  public async getData<T = any>(key): Promise<T> {
    /* when offline */
    const item = await this.localDataService.getItem(key);

    /* when online */

    return item;
  }

  public saveData(key, value) {
    this.localDataService.setItem(key, value).then(function () {
      return true;
    });
  }

  public deleteData(key) {
    this.localDataService.setItem(key, null).then(function () {
      return true;
    });
  }




}
