import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import {combineLatest, Subscription} from 'rxjs';
import {UserService} from '../../services/data-sync/user.service';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../../services/filter.service';
import {Permission} from '../../models/permission.model';
import {PermissionService} from '../../services/data-sync/permission.service';

// import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
  providers: [FilterService],
  styleUrls: ['./sidebar-side.component.scss']
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  // private sidebarPS: PerfectScrollbar;
  public menuItems: any[] = [];
  userId;
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public userData: any = {
    name: null
  };

  public environment;


  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    public filterService: FilterService,
    public permissionService: PermissionService
  ) {
/*    this.authService._currentAuthenticatedUser.subscribe((val) => {
      this.userData = this.authService.currentAuthenticatedUser;
      console.log(this.userData);
      environment.currentAuthUser = this.authService.currentAuthenticatedUser;
    });*/


    this.environment = environment;
    this.userId = environment.currentAuthUser.__document__key;
    console.log(this.userId);
    }

  ngOnInit() {
    const menuItemsSub$ = this.navService.menuItems$;

    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.userData = environment.currentAuthUser;

      const userRole = environment.currentAuthUser.permissions[0];
      this.permissionService.role = environment.currentAuthUser.permissions[0];

      this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
      this.addMenuItems(menuItem, userRole);

      // Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;

    });

    /*combineLatest(auth$, menuItemsSub$, (auth, menuItem) => ({auth, menuItem}))
      .subscribe(pair => {
        this.userData = this.authService.currentAuthenticatedUser;
        environment.currentAuthUser = this.authService.currentAuthenticatedUser;

        this.authService._authInitCompleted.next(true);
        this.authService.authInitCompleted = true;

        const userRole = environment.currentAuthUser.permissions[0];
        this.permissionService.role = environment.currentAuthUser.permissions[0];

        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.addMenuItems(pair.menuItem, userRole);

        // Checks item list has any icon type.
        this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
      });*/

  }

  addMenuItems(menuItem, userRole) {
    menuItem.forEach( (item, index) => {
      /* check if item should be visible */
      let visible;
      if (item.permissions) {
        visible = this.permissionService.checkRolePermissions(userRole, item.permissions);
      }
      if (visible === true) {
        this.menuItems.push(item);
      }
      if (visible === false) {
      }
      if (visible === null) {
        this.menuItems.push(item);
      }

    });
  }


  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.sidebarPS = new PerfectScrollbar('#scroll-area', {
    //     suppressScrollX: true
    //   })
    // })
  }
  ngOnDestroy() {
    // if(this.sidebarPS) {
    //   this.sidebarPS.destroy();
    // }
    if(this.menuItemsSub) {
      this.menuItemsSub.unsubscribe()
    }
  }

}
