import {Injectable} from '@angular/core';
import {FilterService} from '../filter.service';
import {Permission} from '../../models/permission.model';

@Injectable()
export class PermissionService {
  role;
  permissionsFromModel;

  constructor(private filterService: FilterService) {
    this.permissionsFromModel = new Permission();
  }

  checkRolePermissions(role, permissions) {
    const roleKey = this.filterService.findArrayMultiKey(this.permissionsFromModel.roles,
      'name', role);

    const checkPermissions = [];

    permissions.forEach((permission) => {
      if (this.permissionsFromModel.roles[roleKey].permissions.includes(permission)) {
        checkPermissions.push(true);
      } else {
        checkPermissions.push(false);
      }

    });

    return (checkPermissions.includes(false)) ? false : true;

  }
}
