import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

const countriesIsoPackage = require('i18n-iso-countries');

@Injectable()
export class CountryService {
  countries = [];

  constructor (private translateService: TranslateService) {
    /* countries init */
    countriesIsoPackage.registerLocale(require('i18n-iso-countries/langs/' + this.translateService.currentLang + '.json'));
    const countries = countriesIsoPackage.getNames(this.translateService.currentLang);
    Object.entries(countries).forEach(([key, val]) => {
      this.countries[key] = val;
    });
    this.countries.sort();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      countriesIsoPackage.registerLocale(require('i18n-iso-countries/langs/' + this.translateService.currentLang + '.json'));
      const countriesInSubscribe = countriesIsoPackage.getNames(this.translateService.currentLang);
      Object.entries(countriesInSubscribe).forEach(([key, val]) => {
        this.countries[key] = val;
      });
      this.countries.sort();
    });
  }

  getName(country_code, lang) {
    const countriesTmp = [];
    countriesIsoPackage.registerLocale(require('i18n-iso-countries/langs/' + lang + '.json'));
    const countriesInSubscribe = countriesIsoPackage.getNames(lang);
    Object.entries(countriesInSubscribe).forEach(([key, val]) => {
      countriesTmp[key] = val;
    });

    countriesTmp.sort();

    return countriesTmp[country_code];
  }
}
