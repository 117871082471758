/*
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {AddressService} from '../../shared/services/data-sync/address.service';
import {Address} from '../../shared/models/address.model';

@Injectable()
export class AddressResolve implements Resolve<Address[]> {

  constructor(private addressService: AddressService) {}

  resolve(route: ActivatedRouteSnapshot) {
    /!*return this.addressService.getAddresses();*!/
  }
}
*/


export class AddressResolve {

}
